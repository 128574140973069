import { useHistory } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';

import { ReactComponent as SyntherLogoMark } from 'assets/logo-mark.svg';
import { ReactComponent as SyntherLogo } from 'assets/logo.svg';

import ILogo from './types';

const Logo = (props: ILogo) => {
  const history = useHistory();

  return (
    <Stack
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      mt={props.size === 'large' ? 5 : 0}
      {...(props.removeLink
        ? {}
        : {
            sx: { cursor: 'pointer' },
            onClick: () => history.push(props.overrideLinkUrl ? props.overrideLinkUrl : '/'),
          })}
    >
      <SyntherLogoMark
        width={props.size === 'large' ? '100px' : props.size === 'medium' ? '50px' : '30px'}
        height="100%"
      />
      {!props.onlyMark && (
        <SyntherLogo
          width={props.size === 'large' ? '400px' : props.size === 'medium' ? '175px' : '100px'}
          height="100%"
          style={{ marginLeft: '4px' }}
        />
      )}
    </Stack>
  );
};

export default Logo;
