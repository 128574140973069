import { Star } from '@mui/icons-material';
import { green } from '@mui/material/colors';

import { SubscribeButton } from 'style/common';
import { THEME } from 'utils/constants';

const PremiumButton = ({ onClick, children }) => {
  return (
    <SubscribeButton
      onClick={onClick}
      sx={{
        backgroundColor: green[500],
        color: THEME.IMPORTANT_TEXT,
        textTransform: 'none',
        fontWeight: '600',
        borderRadius: '.4rem',
        padding: '1rem',
        height: '2rem',
      }}
    >
      <Star />
      {children}
    </SubscribeButton>
  );
};

export default PremiumButton;
