import styled from 'styled-components';

import { THEME } from 'utils/constants';

export const StyledNav = styled.nav`
  display: block;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  @media only screen and (max-height: 480px) {
    display: none;
  }
`;

export const StyledUnorderedList = styled.ul`
  padding: 0;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  border-radius: 2rem;
`;

export const StyledListItem = styled.li`
  display: inline-block;
`;

export const StyledLink = styled.a`
  user-select: none;
  font-family: Montserrat;
  font-size: 1rem;
  position: relative;
  display: inline-block;
  background: ${THEME.SECONDARY};
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 1rem 3rem 1rem 2rem;
  &:after,
  :before {
    position: absolute;
    content: '';
    height: 0;
    width: 1px;
    top: 50%;
    left: -2rem;
    margin-top: -2rem;
    border: 2rem solid ${THEME.SECONDARY};
    border-right: 0 !important;
    border-left-color: transparent !important;
  }
  &:before {
    left: -2.1rem;
    border: 2rem solid ${THEME.ACCENT};
  }
  &:hover {
    background: ${THEME.ACCENT};
  }
  &:hover:after {
    border-color: ${THEME.ACCENT};
  }
`;
