import { useState, useEffect } from 'react';

/**
 * This hook is used to start a rate-limiting timer when a request is rate-limited.
 * @returns [number, React.Dispatch<React.SetStateAction<number>>]
 */
const useRateLimiterHook = (): [number | null, React.Dispatch<React.SetStateAction<number>>] => {
  // retryAfterTime being null means no rate limiting is active
  const [retryAfterTime, setRetryAfterTime] = useState<number | null>(null);

  // this effect will decrease the retryAfterTime by 1 every second if it is not null
  useEffect(() => {
    if (retryAfterTime !== null) {
      setTimeout(() => {
        setRetryAfterTime((currentValue) => {
          if (currentValue <= 0) {
            return null;
          }
          return currentValue - 1;
        });
      }, 1000);
    }
  }, [retryAfterTime]);

  // invoke setRetryAfterTime with the value of Retry-After header to start rate-limiting timer
  return [retryAfterTime, setRetryAfterTime];
};

export default useRateLimiterHook;
