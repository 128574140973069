import React, { useState } from 'react';
import VideoGrid, { IMediaProps } from '../VideoGrid/VideoGrid';

const Twitch = () => {
  const [data, setData] = useState<IMediaProps[]>();

  return (
    <>
      <VideoGrid data={data} />
    </>
  );
};

export default Twitch;
