import { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Avatar, Typography, Box, Grid, IconButton, Tooltip } from '@mui/material';
import { SettingsSharp, PeopleAlt, Start, PlaylistPlay, Home, Widgets } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import Twemoji from 'react-twemoji';

import ProfileMenu from 'components/common/ProfileMenu';
import RoomSettings from 'components/common/RoomSettings';
import { THEME } from 'utils/constants';
import { GlobalContext } from 'App';
import ConfirmationModal from 'components/common/ConfirmationModal/ConfirmationModal';
import { RoomContext } from 'views/Room/Room';
import { IClientMessagePayload, IServerMessagePayload } from 'views/Room/socketEventTypes';

import GuestLogin from './GuestLogin';
import ChatTextField from './ChatTextField';
import Playlist from './Playlist';
import ParticipantList from './ParticipantList';
import { ChatboxWrapper, ChatboxContainer, ChatboxHeader } from './style';
import { MessageType, IMessageData } from './types';

const Chatbox = () => {
  const { t } = useTranslation();
  const { socketRef, roomRef, setBrowseMediaPopupVisible, isOverlayMenuVisible, setOverlayMenuVisible } =
    useContext(RoomContext);
  const { isLoggedIn, userProfileData, invokeAuthenticatedCallback, isMobileDevice } = useContext(GlobalContext);
  const history = useHistory();

  const [messages, setMessages] = useState<IMessageData<'server' | 'client'>[]>([]);

  const [isRoomSettingsVisible, toggleRoomSettingsView] = useState(false);
  const [isPlaylistViewVisible, setPlaylistViewVisible] = useState(false);
  const [isParticipantListViewVisible, toggleParticipantListView] = useState(false);
  const [showToggleChatboxBtn, setShowToggleChatboxBtn] = useState(false);
  const [isChatboxVisible, toggleChatboxVisibility] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);

  // confirmation modal
  const [shouldConfirmLeaveAction, setShouldConfirmLeaveAction] = useState(false);

  const chatboxRef = useRef<any>();
  const establishedSocketEventsRef = useRef(false);

  const addMessage = (type: MessageType, payload: IClientMessagePayload | IServerMessagePayload) => {
    setMessages((messages) => [
      ...messages,
      {
        type,
        payload,
      },
    ]);
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const socket = socketRef.current;

    if (!establishedSocketEventsRef.current) {
      socket.on('clientMessage', async (data) => addMessage('client', data));
      socket.on('serverMessage', async (data) => addMessage('server', data));

      establishedSocketEventsRef.current = true;
    }

    // clean-up event listeners
    return () => {
      socket.off('clientMessage');
      socket.off('serverMessage');
      establishedSocketEventsRef.current = false;
    };
  }, [roomRef, socketRef]);

  return (
    <ChatboxWrapper
      style={{
        width: isChatboxVisible ? '100%' : '0',
        maxWidth: isMobileDevice ? '100%' : '22%',
        maxHeight: isMobileDevice ? '50%' : '100%',
      }}
    >
      {!isMobileDevice && !isChatboxVisible && isOverlayMenuVisible && (
        <IconButton
          sx={{ position: 'absolute', top: '50%', right: '1rem', bgcolor: 'rgba(255,255,255,0.1)', zIndex: '99999' }}
          onClick={() => toggleChatboxVisibility(true)}
          onMouseEnter={() => setOverlayMenuVisible(true)}
        >
          <Start sx={{ color: THEME.TEXT, transform: 'scaleX(-1)' }} />
        </IconButton>
      )}
      <ChatboxContainer>
        {isLoggedIn ? (
          <Box display="flex" flexDirection="column" flexWrap="nowrap" style={{ height: '100%', position: 'relative' }}>
            <Playlist isVisible={isPlaylistViewVisible} toggle={setPlaylistViewVisible} />
            <ParticipantList isVisible={isParticipantListViewVisible} toggle={toggleParticipantListView} />
            <ChatboxHeader>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ flexWrap: 'nowrap' }}
              >
                <Grid item>
                  <Tooltip title={t('leave_room')}>
                    <IconButton onClick={() => setShouldConfirmLeaveAction(true)} size="medium">
                      <Home sx={{ color: red[400] }} />
                    </IconButton>
                  </Tooltip>
                  <ConfirmationModal
                    isVisible={shouldConfirmLeaveAction}
                    title={t('leave_room_confirmation')}
                    onConfirmCbk={() => history.push('/')}
                    cancelText={t('no')}
                    onCancelCbk={() => setShouldConfirmLeaveAction(false)}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title={t('media_browser.title')}>
                    <IconButton onClick={() => setBrowseMediaPopupVisible(true)} size="medium">
                      <Widgets style={{ color: THEME.ACCENT }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={t('playlist.title')}>
                    <IconButton onClick={() => setPlaylistViewVisible(true)} size="medium">
                      <PlaylistPlay style={{ color: THEME.TEXT }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={t('participants_view.title')}>
                    <IconButton onClick={() => toggleParticipantListView(true)} size="medium">
                      <PeopleAlt style={{ color: THEME.TEXT }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={t('settings.title')}>
                    <IconButton
                      onClick={() => invokeAuthenticatedCallback(() => toggleRoomSettingsView(true))}
                      size="medium"
                    >
                      <SettingsSharp style={{ color: THEME.TEXT }} />
                    </IconButton>
                  </Tooltip>
                  <RoomSettings isVisible={isRoomSettingsVisible} toggle={toggleRoomSettingsView} />
                </Grid>
                <Grid item>
                  <Tooltip title={t('settings.account_section.title')}>
                    <IconButton
                      onClick={(e: any) => invokeAuthenticatedCallback(() => setAnchorEl(e.currentTarget))}
                      size="medium"
                    >
                      <Avatar
                        alt="Account Avatar"
                        src={userProfileData?.avatar}
                        sx={{
                          width: '25px',
                          height: '25px',
                          backgroundColor: userProfileData?.avatar == null ? userProfileData.accent_color : 'none',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </ChatboxHeader>
            {!isMobileDevice && showToggleChatboxBtn && (
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '-20px',
                  bgcolor: 'rgba(255,255,255,0.1)',
                }}
                onMouseEnter={() => setShowToggleChatboxBtn(true)}
                onMouseLeave={() => setShowToggleChatboxBtn(false)}
                onClick={() => toggleChatboxVisibility(false)}
              >
                <Start sx={{ color: THEME.TEXT }} />
              </IconButton>
            )}
            <Box
              ref={chatboxRef}
              className="custom-scrollbar"
              flexGrow={1}
              style={{
                overflowX: 'hidden',
                padding: '1rem 1rem 1rem 0.3rem',
                scrollBehavior: 'smooth',
              }}
              onMouseOver={() => setShowToggleChatboxBtn(true)}
              onMouseLeave={() => setShowToggleChatboxBtn(false)}
            >
              {messages.map((message, index) => {
                if (message.type === 'server') {
                  const messagePayload = message.payload as IServerMessagePayload;
                  return (
                    <Grid key={'serverMessage' + index} container direction="row" justifyContent="center">
                      <Grid item>
                        <div
                          style={{
                            overflowWrap: 'anywhere',
                            backgroundColor: 'unset',
                          }}
                        >
                          <Typography
                            style={{
                              padding: '0.5rem',
                              paddingTop: '0',
                              color: THEME.IMPORTANT_TEXT,
                            }}
                            align="center"
                          >
                            {t(messagePayload.i18Label, messagePayload.values)}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  );
                } else if (message.type === 'client') {
                  const messagePayload = message.payload as IClientMessagePayload;
                  return (
                    <Grid key={'message' + index} container direction="row" style={{ paddingBottom: '1rem' }}>
                      <Grid item xs={2} pt={2} display="flex" alignItems="start" justifyContent="center">
                        <Avatar
                          alt="Account Avatar"
                          src={userProfileData?.avatar}
                          sx={{
                            width: '25px',
                            height: '25px',
                            backgroundColor: userProfileData?.avatar == null ? userProfileData.accent_color : 'none',
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            overflowWrap: 'anywhere',
                            boxShadow: '0 0 35px rgba(0,0,0,0.25)',
                            backgroundColor: THEME.SECONDARY,
                            borderRadius: '1rem',
                            padding: '1rem',
                          }}
                        >
                          <Typography
                            style={{
                              paddingBottom: '0',
                              color: messagePayload.color,
                            }}
                            fontWeight="bold"
                          >
                            {messagePayload.from}
                          </Typography>
                          <Typography component="span" style={{ paddingTop: '0', color: THEME.TEXT }}>
                            <Twemoji options={{ className: 'twemoji' }}>{messagePayload.message}</Twemoji>
                            {/* {twemoji.parse(messagePayload.message)} */}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </Box>
            <ChatTextField />
          </Box>
        ) : (
          <GuestLogin />
        )}
        <ProfileMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </ChatboxContainer>
    </ChatboxWrapper>
  );
};

export default Chatbox;
