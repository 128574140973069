import React, { useEffect, useContext, useCallback } from 'react';
import { RoomContext } from '../Room';
import { IPlayerState, MediaType } from '../socketEventTypes';

const convertIdtoURL = (id: string, mediaType: MediaType) => {
  if (mediaType === 'YouTube' && !id.includes('?v=')) {
    return `https://www.youtube.com/watch?v=${id}`;
  } else if (mediaType === 'Twitch') {
    return `https://www.twitch.tv/videos/${id}`;
  } else if (mediaType === 'SoundCloud') {
    return `https://soundcloud.com/${id}`;
  } else if (mediaType === 'Spotify') {
    return `https://open.spotify.com/track/${id}`;
  }
  return id;
};

const useSocket = (
  playerState: IPlayerState,
  setPlayerState: React.Dispatch<React.SetStateAction<IPlayerState>>,
  playerRef: React.MutableRefObject<any>,
  supressEvents: React.MutableRefObject<boolean>,
) => {
  const { socketRef, roomRef, setCurrentUserSocketId } = useContext(RoomContext);

  const getHostPlayerState = useCallback(
    async (data) => {
      socketRef.current.emit('playerEvent', {
        currentMediaType: playerState.currentMediaType,
        source: playerState.source,
        tracks: playerState.tracks,
        hostID: playerState.hostID,
        playing: playerRef.current.player.isPlaying,
        time: playerRef.current.getCurrentTime(),
        playBackRate: playerState.playBackRate,
        toUser: data.fromUser,
      });
    },
    [playerState, playerRef, socketRef],
  );

  useEffect(() => {
    socketRef.current.on('getHostPlayerState', getHostPlayerState);
    return () => {
      socketRef.current.off('getHostPlayerState');
    };
  }, [getHostPlayerState, socketRef]);

  useEffect(() => {
    // connected- if host, get initial state. if viewer, get host state (host & viewer)
    socketRef.current.on('connected', async (roomData) => {
      const host = roomData.room.hostSocketId;
      roomRef.current = roomData.room;
      setCurrentUserSocketId(roomData.currentUserSocketId);
      if (roomData.currentUserSocketId === host) {
        // get intial player state from server (host only)
        const newPlayerState: Partial<IPlayerState> = {
          currentMediaType: roomData.room.media,
          source: roomData.room.src[roomData.room.media],
          tracks: roomData.room.tracks,
          hostID: host,
          playing: roomData.room.playing,
        };
        setPlayerState((prevState) => ({ ...prevState, ...newPlayerState }));
      } else {
        // get host's player state (viewer only)
        socketRef.current.emit('getHostPlayerState');
      }
      document.title = `Synther | ${roomData.room.media}`;
    });

    // playerEvent- captures updates from host (viewers only)
    socketRef.current.on('playerEvent', async (data) => {
      const update: Partial<IPlayerState> = {};
      console.log(data);
      supressEvents.current = true; // supress viewer events while state is updating
      // unpack the payload and update the player state
      if (data?.currentMediaType != null) {
        update.currentMediaType = data.currentMediaType;
      }
      if (data?.source != null) {
        update.source = convertIdtoURL(data.source, update.currentMediaType ?? playerState.currentMediaType);
      }
      if (data?.tracks != null) {
        update.tracks = data.tracks;
      }
      if (data?.hostID != null) {
        update.hostID = data.hostID;
      }
      if (data?.playing != null) {
        update.playing = data.playing;
      }
      if (data?.time != null) {
        // time is not bound to state, need to seek explicitly
        update.time = data.time;
        playerRef.current.seekTo(data.time, 'seconds');
      }
      if (data?.playBackRate != null) {
        // playback rate not bound to state, need to set explicitly
        update.playBackRate = data.playBackRate;
        if (playerRef.current.getInternalPlayer() !== null) {
          playerRef.current.getInternalPlayer().setPlaybackRate(data.playBackRate);
        }
      }
      setPlayerState((prevState) => ({ ...prevState, ...update })); // update viewer state
      console.log(update, 'viewer update');
    });
  }, []);
};

export default useSocket;
