import styled from 'styled-components';
import { styled as styledMUI } from '@mui/system';

import { THEME } from 'utils/constants';

export const CoreContainer = styled.div`
  margin-top: 4rem;
  background-color: ${THEME.SECONDARY};
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
  @media only screen and (max-height: 800px) {
    justify-content: center;
    padding: 5rem 0;
  }
`;

export const PurchaseUltraContainer = styled.div`
  background: linear-gradient(326deg, #fc3a4c -25%, #9b6afb 100%);
  padding: 3rem;
  border-radius: 2rem;
  min-width: 30rem;
`;

export const MoreToComeContainer = styled.div`
  background-color: ${THEME.TERTIARY};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
`;
