import { useRef, useContext, useEffect, useState, useCallback } from 'react';
import ReactPlayer from 'react-player/lazy';

import { GlobalContext } from 'App';
import BrowseModal from 'views/Room/BrowseModal/BrowseModal';
import { RoomContext } from 'views/Room/Room';

import { VideoPlayerWrapper } from './style';
import useSocket from './SocketEvents';
import VideoPlayerContext from './VideoPlayerContext';
import { IPlayerStateToServer, IPlayerState, MediaType } from '../socketEventTypes';

const VideoPlayer = () => {
  const { isLoggedIn } = useContext(GlobalContext);
  const { socketRef, setBrowseMediaPopupVisible, setOverlayMenuVisible, isMuted, isHost } = useContext(RoomContext);

  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const hideOverlayTimeout = useRef<NodeJS.Timeout>(null);

  // player state
  const [playerState, setPlayerState] = useState<IPlayerState>({
    source: '',
    playing: true,
    tracks: [],
    currentMediaType: 'YouTube',
    time: 0,
    hostID: '',
    playBackRate: 1,
  });
  const playerRef = useRef<any>();
  const prevTime = useRef<any>();
  const supressEvents = useRef<boolean>(false);

  useSocket(playerState, setPlayerState, playerRef, supressEvents);

  // playerState hook
  useEffect(() => {
    if (isHost) {
      prevTime.current = playerState.time;
    } // update prevTime to handle paused seeking
    if (supressEvents.current) {
      // supress events while viewer is getting host state (viewer only)
      setTimeout(() => {
        supressEvents.current = false;
      }, 750); // 750 seems to work in most cases...TODO: overlay unclickable view over the player for this duration
    }
  }, [playerState, isHost]);

  // Runs once when player ready
  const onPlayerReady = () => {
    if (!isHost) {
      socketRef.current.emit('getHostPlayerState');
    } // sync with host again to compensate for loading latency (viewer only)
    setInterval(() => {
      // seek timer
      if (
        playerRef.current &&
        !playerRef.current.player?.isPlaying &&
        prevTime.current !== playerRef.current.getCurrentTime()
      ) {
        prevTime.current = playerRef.current.getCurrentTime();
        helper.onSeek();
      }
    }, 750);
  };

  const updatePlayerState = useCallback(
    (newPlayerState: IPlayerStateToServer) => {
      // helper send call to modify playerState and disallows unauthorized events
      if (isHost) {
        console.log(newPlayerState, 'host emit');
        socketRef.current.emit('playerEvent', newPlayerState);
        setPlayerState((prevState) => ({ ...prevState, ...newPlayerState }));
      } else if (process.env.NODE_ENV === 'development' && supressEvents.current) {
        // keep this for debugging
        console.log('event suppressed');
      } else {
        // capture unauthorized state changes and resync with host
        console.log('viewer state change');
        setPlayerState((prevState) => ({ ...prevState, ...newPlayerState })); // prevent viewer player state from de-syncing from its ref
        socketRef.current.emit('getHostPlayerState');
      }
    },
    [isHost, socketRef],
  );

  const helper = {
    onPlaybackStateChange: (playing) =>
      updatePlayerState({
        playing: playing,
        time: playerRef.current.getCurrentTime(),
      }),
    onPlaybackRateChange: () =>
      updatePlayerState({
        playBackRate: playerRef.current.getInternalPlayer().getPlaybackRate(),
        time: playerRef.current.getCurrentTime(),
      }),
    onSeek: () =>
      updatePlayerState({
        time: playerRef.current.getCurrentTime(),
      }),
  };

  const onHandleSearchFieldKeyPress = (newSourceValue: string) => {
    if (newSourceValue !== '') {
      playerRef.current.seekTo(0, 'seconds');
      updatePlayerState({
        source: newSourceValue,
        time: 0,
      });
      setBrowseMediaPopupVisible(false);
    }
  };

  const onSwitchMedia = (newMediaType: MediaType) => {
    setBrowseMediaPopupVisible(true);
    updatePlayerState({
      currentMediaType: newMediaType,
    });
  };

  const changeVideoSource = useCallback(
    (newVideoSource: string, newMediaType: MediaType = 'YouTube') => {
      updatePlayerState({ source: newVideoSource, time: 0, currentMediaType: newMediaType });
    },
    [updatePlayerState],
  );

  return (
    <VideoPlayerContext.Provider value={{ changeVideoSource }}>
      <VideoPlayerWrapper
        flexGrow={1}
        onMouseEnter={() => {
          clearTimeout(hideOverlayTimeout.current);
          setOverlayMenuVisible(true);
          hideOverlayTimeout.current = setTimeout(() => setOverlayMenuVisible(false), 2000);
        }}
        onMouseOut={() => setOverlayMenuVisible(false)}
        style={{
          ...(isMobileDevice ? { height: 'auto', minHeight: '50%' } : { flex: 1, height: '100%' }),
        }}
      >
        <BrowseModal
          currentMedia={playerState.currentMediaType}
          onHandleSearchFieldKeyPress={onHandleSearchFieldKeyPress}
          onSwitchMediaCbk={onSwitchMedia}
        />
        {!isLoggedIn && (
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              backdropFilter: 'blur(1rem)',
              background: 'rgba(0,0,0,0.85)',
            }}
          />
        )}
        <ReactPlayer
          ref={playerRef}
          playing={playerState.playing}
          controls
          url={playerState.source}
          muted={isMuted}
          width="100%"
          height="auto"
          style={{ aspectRatio: '16/9' }}
          onPlay={() => helper.onPlaybackStateChange(true)}
          onPause={() => helper.onPlaybackStateChange(false)}
          onPlaybackRateChange={() => helper.onPlaybackRateChange()}
          onReady={onPlayerReady}
          onStart={() => {
            // playerRef doest update initial state without this
            if (process.env.NODE_ENV === 'development') {
              console.log('onStart');
            }
          }}
          onEnded={() => isHost && socketRef.current?.emit('playNextPlaylist')}
          // config={{
          //   file: {
          //     tracks: playerState.tracks,
          //   },
          // }}
        />
      </VideoPlayerWrapper>
    </VideoPlayerContext.Provider>
  );
};

export default VideoPlayer;
