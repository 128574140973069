import styled from 'styled-components';

import { Paper } from '@mui/material';

import { THEME } from 'utils/constants';

export const Container = styled.div`
  margin: 1rem;
`;

export const CreateRoomButton = styled(Paper)`
  background-color: ${THEME.SECONDARY} !important;
  color: ${THEME.ACCENT} !important;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 2rem;
  text-decoration: none;
  padding: 1rem;
  border-radius: 4rem !important;
  cursor: pointer;
  overflow: hidden;
  transition: all 100ms !important;
  user-select: none;
  &:hover {
    background-color: ${THEME.ACCENT} !important;
    color: ${THEME.TEXT} !important;
    border-radius: 1rem !important;
  }
`;
