import { useState, useEffect, useContext, useCallback } from 'react';

import { fetchYoutubeTrendingVideos } from 'api/youtube';
import VideoPlayerContext from 'views/Room/VideoPlayer/VideoPlayerContext';
import { RoomContext } from 'views/Room/Room';
import { MediaType } from 'views/Room/socketEventTypes';

import VideoGrid, { IMediaProps } from '../VideoGrid/VideoGrid';

const YouTube = () => {
  const { socketRef, setBrowseMediaPopupVisible } = useContext(RoomContext);
  const { changeVideoSource } = useContext(VideoPlayerContext);
  const [data, setData] = useState<IMediaProps[]>();

  const onCardClicked = useCallback(
    (url) => {
      changeVideoSource(url, 'YouTube');
      setBrowseMediaPopupVisible(false);
    },
    [changeVideoSource, setBrowseMediaPopupVisible],
  );

  useEffect(() => {
    fetchYoutubeTrendingVideos()
      .then((videos) => {
        const medias: IMediaProps[] = [];
        videos.forEach((video) => {
          const url = `https://www.youtube.com/watch?v=${video.videoId}`;
          const media: IMediaProps = {
            title: video.title,
            imageURL: `https://img.youtube.com/vi/${video.videoId}/maxresdefault.jpg`,
            url: url,
            avatar: '',
            author: video.uploader,
            timestamp: video.dateTimestamp,
            numOfViews: video.numOfviews,
            onCardClicked: onCardClicked,
            onAddToPlaylist: () => socketRef.current?.emit('addToPlaylist', url),
          };
          medias.push(media);
        });
        setData(medias);
      })
      .catch(() => {
        // TODO error handling show simple message in UI
      });
  }, [onCardClicked, socketRef]);

  return (
    <>
      <VideoGrid data={data} />
    </>
  );
};

export default YouTube;
