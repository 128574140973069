import styled from 'styled-components';

export const LoginFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const NeedAccountContainer = styled.div`
  display: flex;
  align-items: center;
`;
