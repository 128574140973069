import React, { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Slide, TextField, Grid, Typography } from '@mui/material';
import { ScreenShare as ScreenShareLogo, Close, ArrowBack } from '@mui/icons-material';

import { GlobalContext } from 'App';
import { THEME } from 'utils/constants';
import { YouTubeSVG, YouTubeWhiteSVG, TwitchSVG, SpotifySVG, SoundCloudSVG } from 'assets/SVG';
import WEB_TORRENT_LOGO from 'assets/icons/webtorrent.png';

import { MediaType } from '../socketEventTypes';
import { RoomContext } from '../Room';

import YouTube from './Youtube';
import Soundcloud from './Soundcloud';
import Spotify from './Spotify';
import Twitch from './Twitch';
import ScreenShare from './ScreenShare';
import WebTorrent from './WebTorrent';
import {
  ModalContainer,
  SearchBarWrapper,
  StyledButton,
  TwitchButton,
  SpotifyButton,
  SoundcloudButton,
  ScreenshareButton,
  CustomSVGWrapper,
} from './style';
import IBrowseModal from './types';

const BrowseModal = (props: IBrowseModal) => {
  const { t } = useTranslation();
  const { isMobileDevice } = useContext(GlobalContext);
  const { isBrowseMediaPopupVisible, setBrowseMediaPopupVisible } = useContext(RoomContext);
  const [didSelectMedia, setDidSelectMedia] = useState(false);
  const [whichHighlighted, setWhichHighlighted] = useState<MediaType>();
  const [textfieldValue, setTextfieldValue] = useState('');

  const selectMedia = (newMediaType: MediaType) => {
    setDidSelectMedia(true);
    setWhichHighlighted(null);
    props.onSwitchMediaCbk(newMediaType);
  };

  const onSearchFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTextfieldValue(e.target.value);
      if (didSelectMedia) {
        // handle search as a query to re-populate the video grid
        return;
      }
    },
    [didSelectMedia],
  );

  const onSearchFieldKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        props.onHandleSearchFieldKeyPress(textfieldValue);
        setBrowseMediaPopupVisible(false);
        setTextfieldValue('');
      }
    },
    [textfieldValue],
  );

  return (
    <Slide direction="up" in={isBrowseMediaPopupVisible} mountOnEnter unmountOnExit>
      <ModalContainer>
        <SearchBarWrapper>
          <IconButton
            onClick={() => setDidSelectMedia(false)}
            size="medium"
            style={{ visibility: didSelectMedia ? 'visible' : 'hidden' }}
          >
            <ArrowBack style={{ color: THEME.TEXT }} />
          </IconButton>
          {props.currentMedia !== 'ScreenShare' && (
            <TextField
              value={textfieldValue}
              placeholder={didSelectMedia ? `Search ${props.currentMedia}` : 'Paste a direct link to the video'}
              variant="outlined"
              style={{ width: '100%', ...(isMobileDevice ? {} : { margin: '0 10rem' }) }}
              onChange={onSearchFieldChange}
              onKeyDown={onSearchFieldKeyDown}
              sx={{
                input: {
                  color: THEME.TEXT,
                  background: THEME.SECONDARY, // "#272C38",
                  borderRadius: '0.5rem',
                },
                label: {
                  color: THEME.TEXT,
                },
              }}
            />
          )}
          <IconButton onClick={() => setBrowseMediaPopupVisible(false)} size="large">
            <Close style={{ color: '#fff' }} />
          </IconButton>
        </SearchBarWrapper>
        {didSelectMedia ? (
          <>
            {props.currentMedia === 'YouTube' && <YouTube />}
            {props.currentMedia === 'Twitch' && <Twitch />}
            {props.currentMedia === 'Spotify' && <Spotify />}
            {props.currentMedia === 'SoundCloud' && <Soundcloud />}
            {props.currentMedia === 'WebTorrent' && <WebTorrent />}
            {props.currentMedia === 'ScreenShare' && <ScreenShare />}
          </>
        ) : (
          <Grid container m={1} spacing={1} style={{ padding: isMobileDevice ? '1rem' : '1rem 10rem' }} overflow="auto">
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <StyledButton
                onClick={() => selectMedia('YouTube')}
                onMouseEnter={() => setWhichHighlighted('YouTube')}
                onMouseLeave={() => setWhichHighlighted(null)}
              >
                {whichHighlighted === 'YouTube' ? (
                  <YouTubeSVG width={350} height={350} />
                ) : (
                  <YouTubeWhiteSVG width={350} height={350} />
                )}
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TwitchButton
                onClick={() => selectMedia('Twitch')}
                onMouseEnter={() => setWhichHighlighted('Twitch')}
                onMouseLeave={() => setWhichHighlighted(null)}
              >
                <TwitchSVG width={350} height={350} darkMode={whichHighlighted === 'Twitch'} />
              </TwitchButton>
            </Grid>
            {/* <Grid item xs={4}>
              <Button onClick={selectMedia} value="netflix" style={{ width: "100%", background: "#fff" }}>
                <NetflixSVG width={400} height={400} />
              </Button>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <SpotifyButton
                onClick={() => selectMedia('Spotify')}
                onMouseEnter={() => setWhichHighlighted('Spotify')}
                onMouseLeave={() => setWhichHighlighted(null)}
              >
                <SpotifySVG width={350} height={350} darkMode={whichHighlighted === 'Spotify'} />
              </SpotifyButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <SoundcloudButton
                onClick={() => selectMedia('SoundCloud')}
                onMouseEnter={() => setWhichHighlighted('SoundCloud')}
                onMouseLeave={() => setWhichHighlighted(null)}
              >
                <SoundCloudSVG width={400} height={350} darkMode={whichHighlighted === 'SoundCloud'} />
              </SoundcloudButton>
            </Grid>
            {!isMobileDevice && (
              <>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <StyledButton onClick={() => selectMedia('WebTorrent')} style={{ height: '100%' }}>
                    <CustomSVGWrapper>
                      <img src={WEB_TORRENT_LOGO} alt={'WebTorrent'} style={{ padding: '1rem', height: '100px' }} />
                      <Typography variant="h4" fontWeight={'bold'} style={{ color: '#ed324b' }}>
                        {t('webtorrent')}
                      </Typography>
                    </CustomSVGWrapper>
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ScreenshareButton
                    onClick={() => selectMedia('ScreenShare')}
                    style={{ height: '100%' }}
                    onMouseEnter={() => setWhichHighlighted('ScreenShare')}
                    onMouseLeave={() => setWhichHighlighted(null)}
                  >
                    <CustomSVGWrapper>
                      <ScreenShareLogo
                        fontSize="large"
                        style={{
                          color: whichHighlighted === 'ScreenShare' ? '#FFF' : 'rgb(25 118 210)',
                          width: '100px',
                          height: '100px',
                          padding: '1rem',
                        }}
                      />
                      <Typography
                        variant="h4"
                        fontWeight={'bold'}
                        color={whichHighlighted === 'ScreenShare' ? '#FFF' : 'none'}
                      >
                        {t('screen_share')}
                      </Typography>
                    </CustomSVGWrapper>
                  </ScreenshareButton>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </ModalContainer>
    </Slide>
  );
};

export default BrowseModal;
