import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Slide, Typography, Stack, Tabs, Tab, Tooltip, IconButton, Box } from '@mui/material';
import { Close } from '@mui/icons-material';

import { THEME } from 'utils/constants';
import { DisabledTab } from 'style/common';

import Preferences from './Preferences';
import Overview from './Overview';
import Moderation from './Moderation';

import { Container } from './style';
import IRoomSettings from './types';
import { RoomContext } from 'views/Room/Room';

const VIEWS = [<Preferences />, <Overview />, <Moderation />];

const RoomSettings = (props: IRoomSettings) => {
  const { t } = useTranslation();
  const { currentUserSocketId, roomRef } = useContext(RoomContext);

  const [currentView, setCurrentView] = useState(0);

  return (
    <Modal
      open={props.isVisible}
      onClose={() => props.toggle(false)}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(5px)',
      }}
    >
      <Slide direction="down" in={props.isVisible} timeout={100}>
        <Container>
          <Stack maxHeight="100%">
            <Stack direction="row" justifyContent="space-between" alignItems="center" p={4} pb={0}>
              <Typography variant="h4" marginBottom={1} fontWeight="bold">
                {t('room_settings_title')}
              </Typography>
              <IconButton onClick={() => props.toggle(false)} size="medium">
                <Close sx={{ color: THEME.TEXT }} />
              </IconButton>
            </Stack>
            {currentUserSocketId === roomRef.current?.hostSocketId ? (
              <Tabs
                value={currentView}
                onChange={(_, n) => setCurrentView(n)}
                sx={{ borderBottom: `1px solid ${THEME.ACCENT}` }}
              >
                <Tab label="Preferences" sx={{ textTransform: 'none' }} />
                <Tab label="Overview" sx={{ textTransform: 'none' }} />
                <Tab label="Moderation" sx={{ textTransform: 'none' }} />
              </Tabs>
            ) : (
              <Tabs
                value={currentView}
                onChange={(_, n) => setCurrentView(n)}
                sx={{ borderBottom: `1px solid ${THEME.ACCENT}` }}
              >
                <Tab label="Preferences" sx={{ textTransform: 'none' }} />
                <Tooltip title={'You do not have permissions to modify these settings.'}>
                  <DisabledTab label="Details" sx={{ textTransform: 'none' }} />
                </Tooltip>
                <Tooltip title={'You do not have permissions to modify these settings.'}>
                  <DisabledTab label="Moderation" sx={{ textTransform: 'none' }} />
                </Tooltip>
              </Tabs>
            )}
            <Box height="100%" overflow="auto">
              {VIEWS[currentView]}
            </Box>
          </Stack>
        </Container>
      </Slide>
    </Modal>
  );
};

export default RoomSettings;
