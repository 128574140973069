import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { THEME } from 'utils/constants';
import Socials from 'components/common/Socials';

import { WhatsNewPanelWrapper } from './style';

const DATE = 'October 27, 2024';

const WhatsNewPanel = () => {
  const { t } = useTranslation();

  return (
    <WhatsNewPanelWrapper>
      <Stack flexDirection={'column'} justifyContent="space-between" width="100%">
        <Stack p={2}>
          <Typography variant="h5" color={THEME.TEXT} fontWeight={'bold'}>
            {t('whats_new')}
          </Typography>
          <Typography variant="subtitle2" color={THEME.TEXT_DARK}>
            {DATE}
          </Typography>
        </Stack>
        <Stack p={2} height={'100%'} overflow={'auto'}>
          <Typography variant="body1">{t('whats_new_message')}</Typography>
        </Stack>
        <Stack p={2} bgcolor={THEME.ACCENT} flexDirection={'row'} alignItems={'center'}>
          <Socials />
          <Typography variant="body1">{t('follow_for_updates')}</Typography>
        </Stack>
      </Stack>
    </WhatsNewPanelWrapper>
  );
};

export default WhatsNewPanel;
