import styled from 'styled-components';

import { Typography } from '@mui/material';

import { THEME } from 'utils/constants';

export const EmojiSelectorWrapper = styled.div`
  width: 500px;
  height: 500px;
`;

export const TypeButton = styled.div`
  transition: all 0.4s;
  border-radius: 0.25rem;
  margin-left: 1rem;
  cursor: pointer;
`;

export const StyledTypography = styled(Typography)`
  transition: all 0.4s;
  &:hover {
    color: red;
  }
`;
