import { Stack } from '@mui/material';

import { THEME } from 'utils/constants';
import { useTranslation } from 'react-i18next';


import { StyledNav, StyledUnorderedList, StyledListItem, StyledLink } from './style';

const CreateRoomSteps = () => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center">
      <StyledNav>
        <StyledUnorderedList>
          <StyledListItem>
            <StyledLink>
              <b>{t('create_room')}</b>
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink>
              <b>{t('share_link')}</b>
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink>
              <b>
                <span style={{ color: THEME.TEXT }}>SYN</span>
                <span style={{ color: THEME.ACCENT }}>C TOGE</span>
                <span style={{ color: THEME.TEXT }}>THER</span>
              </b>
            </StyledLink>
          </StyledListItem>
        </StyledUnorderedList>
      </StyledNav>
    </Stack>
  );
};

export default CreateRoomSteps;
