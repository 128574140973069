import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';
import { Drafts } from '@mui/icons-material';

import { GlobalContext } from 'App';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import useRateLimiterHook from 'components/hooks/rateLimiterHook';
import { PrimaryButton, DotFlashing } from 'style/common';

import { Wrapper } from './style';

const VerifyEmail = () => {
  const { t } = useTranslation();
  const { isFullyLoggedIn } = useContext(GlobalContext);
  const location = useLocation();
  const history = useHistory();

  const [responseCode, setResponseCode] = useState<number | null>(null);
  const [retryAfterTime, setRetryAfterTime] = useRateLimiterHook();

  useEffect(() => {
    document.title = 'Synther | Verify Email';
  }, []);

  const fetchEmailVerification = useCallback(async () => {
    try {
      const query = new URLSearchParams(location.search);
      const token = query.get('token');
      if (!token) {
        setResponseCode(400);
        return;
      }
      const response = await fetch(`/api/auth/verify-email?token=${token}`);
      if (response.status === 429) {
        setRetryAfterTime(Number(response.headers.get('Retry-After')));
      }
      setResponseCode(response.status);
    } catch (error) {
      setResponseCode(500);
    }
  }, [location.search, setRetryAfterTime]);

  useEffect(() => {
    if (responseCode === null) {
      fetchEmailVerification();
    }
  }, [responseCode, fetchEmailVerification]);

  return (
    <>
      <Header />
      <Wrapper>
        <Stack gap={10} alignItems={'center'}>
          {responseCode === null ? (
            <>
              <Drafts sx={{ transform: 'scale(4)' }} />
              <Stack alignItems={'center'}>
                <Typography variant="h4" fontWeight={'bold'}>
                  {t('verifying_email_in_progress_title')}
                </Typography>
                <Typography>{t('verifying_email_in_progress_description')}</Typography>
              </Stack>
              <PrimaryButton variant="contained" sx={{ height: '2.5rem', width: '20rem' }} disabled>
                <DotFlashing />
              </PrimaryButton>
            </>
          ) : responseCode === 200 || responseCode === 429 ? (
            <>
              <Drafts sx={{ transform: 'scale(4)' }} color={responseCode === 429 ? 'info' : 'success'} />
              <Stack alignItems={'center'}>
                <Typography variant="h4" fontWeight={'bold'}>
                  {t(responseCode === 429 ? 'too_many_requests' : 'email_verification_success_title')}
                </Typography>
                <Typography variant="h6">
                  {t(responseCode === 429 ? 'please_wait' : 'email_verification_success_description')}
                </Typography>
              </Stack>
              <PrimaryButton
                variant="contained"
                onClick={() => {
                  if (responseCode === 429 && !retryAfterTime) {
                    fetchEmailVerification();
                  } else if (responseCode === 200) {
                    history.push('/app');
                  }
                }}
                sx={{ fontSize: '14px', width: '20rem' }}
              >
                {responseCode === 429
                  ? retryAfterTime
                    ? t(`try_again_in_seconds`, { seconds: retryAfterTime })
                    : t('verify_email')
                  : isFullyLoggedIn
                  ? t('open_synther')
                  : t('login')}
              </PrimaryButton>
            </>
          ) : (
            <>
              <Drafts sx={{ transform: 'scale(4)' }} color="error" />
              <Stack alignItems={'center'}>
                <Typography variant="h4" fontWeight={'bold'}>
                  {t('email_verification_expired_title')}
                </Typography>
                <Typography variant="h6">{t('email_verification_expired_description')}</Typography>
              </Stack>
              <PrimaryButton
                variant="contained"
                onClick={() => history.push('/app')}
                sx={{ fontSize: '14px', width: '20rem' }}
              >
                {isFullyLoggedIn ? t('open_synther') : t('login')}
              </PrimaryButton>
            </>
          )}
        </Stack>
      </Wrapper>
      <Footer />
    </>
  );
};

export default VerifyEmail;
