import styled from 'styled-components';
import { styled as styledMUI } from '@mui/system';

import { Paper } from '@mui/material';

import { THEME } from 'utils/constants';

export const SettingsWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const SettingsContainer = styled.div`
  margin: 2rem;
`;

export const ProfilePreview = styledMUI(Paper)({
  border: `1px solid ${THEME.SECONDARY}`,
  backgroundColor: THEME.SECONDARY,
  marginTop: '1rem',
  position: 'relative',
  borderRadius: '1rem',
});

export const ProfileBannerPreview = styled.div`
  background: ${THEME.ACCENT}; // rgb(7, 124, 192);
  height: 6rem;
  border-radius: 1rem;
`;

export const ProfileAvatarPreview = styled.div`
  background: transparent;
  position: absolute;
  padding: 2rem;
  top: 0;
`;

export const ProfileInfo = styled.div`
  display: block;
  padding: 4rem 4rem 2rem 4rem;
  color: #fff;
`;

export const ProfileParameter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  margin-top: 1.5rem;
`;

export const StyledProfilePreview = styled(ProfilePreview)`
  padding: 1rem;
`;
