import styled from 'styled-components';

export const SortableList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  list-style: none;
  height: 100%;
  overflow: auto;
`;
