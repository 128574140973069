import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  IconButton,
  Modal,
  CircularProgress,
  Typography,
  Stack,
  Box,
  List,
  Divider,
  Button,
  Zoom,
} from '@mui/material';
import { ContentCopyOutlined, Check, Close } from '@mui/icons-material';
import { red, green } from '@mui/material/colors';

import { GlobalContext } from 'App';
import { THEME } from 'utils/constants';
import { SecondaryButton } from 'style/common';
import { inviteToRoom } from 'api/room';

import { StyledTextField, ShareModal, StyledListItem } from './style';
import IInviteFriendsModal from './types';

type ActionState = 'none' | 'progress' | 'success' | 'failure';

const RESET_ACTION_STATE_TIMEOUT_MS = 2000;

interface IFriendRowProps {
  id: number;
  userId: string;
  accentColor: string;
  avatar?: string;
}

const FriendRow = (props: IFriendRowProps) => {
  const { t } = useTranslation();
  const [invitationState, setInvitationState] = useState<ActionState>('none');

  useEffect(() => {
    if (invitationState === 'progress') {
      const sendRequest = async () => {
        const result = await inviteToRoom(props.userId);
        setInvitationState(result ? 'success' : 'failure');
        setTimeout(() => setInvitationState('none'), RESET_ACTION_STATE_TIMEOUT_MS);
      };
      sendRequest();
    }
  }, [invitationState, props.userId]);

  return (
    <StyledListItem key={props.id}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            alt="Friends Avatar"
            src={'/img/default_avatar.jpg'}
            sx={{
              width: '25px',
              height: '25px',
              backgroundColor: props?.avatar == null ? props.accentColor : 'none',
            }}
          />
          <Typography>Friend {props.id}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          {invitationState === 'progress' ? (
            <SecondaryButton>
              <CircularProgress size="24.5px" />
            </SecondaryButton>
          ) : invitationState === 'success' ? (
            <Button variant="contained" color="success" sx={{ fontSize: '15px' }}>
              {t('sent')}
            </Button>
          ) : invitationState === 'failure' ? (
            <Button variant={'contained'} color="error" sx={{ fontSize: '15px' }}>
              {t('failed')}
            </Button>
          ) : (
            <SecondaryButton onClick={() => setInvitationState('progress')}>Invite</SecondaryButton>
          )}
        </Stack>
      </Stack>
    </StyledListItem>
  );
};

const InviteFriendsModal = (props: IInviteFriendsModal) => {
  const { t } = useTranslation();
  const { displayLoginModal } = useContext(GlobalContext);
  const [currentCopyState, setCurrentCopyState] = useState<ActionState>('none');
  const friendList = [];

  useEffect(() => {
    if (currentCopyState === 'failure' || currentCopyState === 'success') {
      setTimeout(() => setCurrentCopyState('none'), RESET_ACTION_STATE_TIMEOUT_MS);
    }
  }, [currentCopyState]);

  const copyToClipboard = (text: string) => {
    setCurrentCopyState('progress');
    navigator.clipboard.writeText(text).then(
      () => setTimeout(() => setCurrentCopyState('success'), 250),
      () => setTimeout(() => setCurrentCopyState('failure'), 250),
    );
  };

  return (
    <Modal
      open={props.isVisible}
      onClose={() => props.toggle(false)}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(5px)',
      }}
    >
      <Zoom timeout={100} unmountOnExit in={props.isVisible}>
        <ShareModal>
          <Stack m={2} spacing={1}>
            <Typography variant="h5" marginBottom={1} fontWeight="bold">
              {t('invite_friends')}
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: 400,
                overflow: 'auto',
                background: THEME.SECONDARY,
                borderRadius: '0.5rem',
              }}
            >
              <List
                sx={
                  friendList.length === 0
                    ? { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', p: 0 }
                    : {}
                }
              >
                {friendList.length === 0 ? (
                  <Button variant="contained" onClick={displayLoginModal}>
                    LOGIN TO INVITE
                  </Button>
                ) : (
                  Array(5)
                    .fill(0)
                    .map((_, index) => <FriendRow id={index + 1} userId={null} accentColor={null} />)
                )}
              </List>
            </Box>
            <Stack spacing={2}>
              <Divider sx={{ borderColor: THEME.SECONDARY }} />
              <Typography variant="body2" align="center" fontWeight="bold">
                {t('or')}
              </Typography>
              <StyledTextField
                id="filled-read-only-input"
                label="Share Link"
                defaultValue={window.location.href}
                InputProps={{
                  readOnly: true,
                  sx: {
                    color: '#868686',
                  },
                  endAdornment: (
                    <IconButton size="large" onClick={() => copyToClipboard(window.location.href)}>
                      {currentCopyState === 'progress' ? (
                        <CircularProgress size="20px" />
                      ) : currentCopyState === 'success' ? (
                        <Check fontSize="small" style={{ color: green[400] }} />
                      ) : currentCopyState === 'failure' ? (
                        <Close fontSize="small" style={{ color: red[400] }} />
                      ) : (
                        <ContentCopyOutlined fontSize="small" style={{ color: THEME.IMPORTANT_TEXT }} />
                      )}
                    </IconButton>
                  ),
                }}
                fullWidth
                variant="filled"
              />
            </Stack>
          </Stack>
        </ShareModal>
      </Zoom>
    </Modal>
  );
};

export default InviteFriendsModal;
