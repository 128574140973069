import { useState, useEffect, useContext, useRef, useMemo, memo } from 'react';
import {
  Stack,
  Box,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  ImageList,
  ImageListItem,
  Tooltip,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { THEME } from 'utils/constants';
import { ITenorResponse, fetchTenorGifs } from 'api/tenor';
import CollapsiblePanel from 'components/common/CollapsiblePanel';
import Twemoji from 'components/common/Twemoji';

import { ChatTextFieldContext } from '../../ChatTextField';

import { EmojiSelectorWrapper, TypeButton } from './style';
import IEmojiSelectorProps, { EmojiType } from './types';
import { LIST_OF_EMOJIS } from './Emojis';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const data = require('./tenorGifs.json') as ITenorResponse;

const EmojiItem = (props: { emoji: string; onClickCbk?: () => void }) => {
  const { editor } = useContext(ChatTextFieldContext);

  return (
    <IconButton
      onClick={() => {
        // setMessage((message) => {
        //   // slice message based on selection
        //   const firstSliceMessage = message.slice(0, cursorPosition.start),
        //     secondSliceMessage = message.slice(cursorPosition.end);
        //   // craft new message with emoji
        //   const newMessage = [firstSliceMessage, props.emoji, secondSliceMessage].join('');
        //   // update selection (cursor index) to be right after the emoji
        //   // +2 because all emojis are 2 characters long
        //   cursorPosition.start = firstSliceMessage.length + 2;
        //   cursorPosition.end = firstSliceMessage.length + 2;
        //   return newMessage;
        // });
        editor.chain().focus().insertContent(props.emoji).run();
        if (props.onClickCbk) {
          props.onClickCbk();
        }
      }}
    >
      <Twemoji emoji={props.emoji} />
    </IconButton>
  );
};

const EmojiSelector = (props: IEmojiSelectorProps) => {
  const { t } = useTranslation();
  const [type, setType] = useState<EmojiType>('emoji');
  const [filter, setFilter] = useState('');
  const [tenorGifs, setTenorGifs] = useState([]);

  const gifBoxRef = useRef<HTMLElement>(null);
  const emojiBoxRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (emojiBoxRef.current) {
      emojiBoxRef.current.scrollTo(0, 0);
    }
  }, [type]);

  useEffect(() => {
    if (type === 'gifs') {
      if (filter === '') {
        // show categories
      } else {
        fetchTenorGifs(filter);
      }
    }
  }, [type, filter]);

  const EmojisMemo = useMemo(
    () => (
      <Box ref={emojiBoxRef} p={2} flex={1} sx={{ overflowY: 'scroll' }}>
        <CollapsiblePanel title="Smileys" open>
          <Grid container>
            {LIST_OF_EMOJIS.filter((item) => item.group === 'Smileys').map(({ emoji }, index) => (
              <Grid item xs={1} key={index}>
                <EmojiItem emoji={emoji} onClickCbk={props.closeEmojiSelector} />
              </Grid>
            ))}
          </Grid>
        </CollapsiblePanel>
        <CollapsiblePanel title="People" open>
          <Grid container>
            {LIST_OF_EMOJIS.filter((item) => item.group === 'People').map(({ emoji }, index) => (
              <Grid item xs={1} key={index}>
                <EmojiItem emoji={emoji} onClickCbk={props.closeEmojiSelector} />
              </Grid>
            ))}
          </Grid>
        </CollapsiblePanel>
        <CollapsiblePanel title="Nature" open>
          <Grid container>
            {LIST_OF_EMOJIS.filter((item) => item.group === 'Nature').map(({ emoji }, index) => (
              <Grid item xs={1} key={index}>
                <EmojiItem emoji={emoji} onClickCbk={props.closeEmojiSelector} />
              </Grid>
            ))}
          </Grid>
        </CollapsiblePanel>
      </Box>
    ),
    [props.closeEmojiSelector],
  );

  return (
    <EmojiSelectorWrapper>
      <Stack flexDirection="column" height={'100%'}>
        <Box p={2} sx={{ boxShadow: 12 }}>
          <Stack flexDirection="row" pb={2}>
            <TypeButton
              onClick={() => setType('gifs')}
              style={{ backgroundColor: type === 'gifs' ? THEME.SECONDARY : 'unset' }}
            >
              <Typography p={1} sx={{ userSelect: 'none' }}>
                GIFs
              </Typography>
            </TypeButton>
            <TypeButton
              onClick={() => setType('emoji')}
              style={{ backgroundColor: type === 'emoji' ? THEME.SECONDARY : 'unset' }}
            >
              <Typography p={1} sx={{ userSelect: 'none' }}>
                Emojis
              </Typography>
            </TypeButton>
            <TypeButton style={{ backgroundColor: THEME.TEXT_DARK, cursor: 'not-allowed' }}>
              <Tooltip title={`${t('coming_soon')}!`}>
                <Typography p={1} color={THEME.TEXT} sx={{ userSelect: 'none' }}>
                  Reactions
                </Typography>
              </Tooltip>
            </TypeButton>
          </Stack>
          <Stack flexDirection="row" alignItems="center">
            <TextField
              id="emoji-search-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: THEME.IMPORTANT_TEXT }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                'input': {
                  color: THEME.IMPORTANT_TEXT,
                  background: '#272C38',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#272C38',
                },
              }}
              placeholder={type === 'gifs' ? 'Search Tenor' : type === 'emoji' ? 'Search for an emoji' : null}
              autoComplete="off"
              value={filter}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)}
              fullWidth
            />
            {type === 'emoji' && (
              <IconButton sx={{ ml: 2 }}>
                <Twemoji emoji="👋" />
              </IconButton>
            )}
          </Stack>
        </Box>
        {type === 'gifs' ? (
          <Box ref={gifBoxRef} p={2} sx={{ overflowY: 'scroll' }}>
            <ImageList variant="masonry" cols={2} gap={16}>
              {data.results.map((item) => (
                <ImageListItem key={item.id}>
                  <img
                    src={item.media_formats.tinygif.url}
                    alt={item.title}
                    loading="lazy"
                    style={{ borderRadius: '1rem' }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        ) : type === 'emoji' ? (
          filter === '' ? (
            EmojisMemo
          ) : (
            <Box p={2} flex={1} sx={{ overflowY: 'scroll' }}>
              <Grid container>
                {LIST_OF_EMOJIS.filter(({ tags }) =>
                  tags.some((tag) => tag.toLowerCase().includes(filter.toLowerCase())),
                ).map(({ emoji }, index) => (
                  <Grid xs={1} key={index}>
                    <EmojiItem emoji={emoji} onClickCbk={props.closeEmojiSelector} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )
        ) : null}
      </Stack>
    </EmojiSelectorWrapper>
  );
};

export default memo(EmojiSelector);
