import styled from 'styled-components';

import { THEME } from 'utils/constants';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${THEME.SECONDARY};
  margin-top: 4px;
  border-radius: 1rem 1rem 0 0;
  transition: all 0.1s;
`;

export const CustomTextArea = styled.div`
  width: 100%;
  height: 100%;
  white-space: break-spaces;
  overflow: hidden;
  outline: none;
`;

export const BubbleMenuWrapper = styled.div`
  background-color: ${THEME.PRIMARY};
  border-radius: 0.5rem;
`;
