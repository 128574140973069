import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';

import { StyledAuthWrapper, AuthContainerWrapper, AuthContainer, ArtworkContainer, Blob } from './style';
import IAuthWrapper from './types';

const AuthWrapper = (props: IAuthWrapper) => {
  const history = useHistory();

  return props.isModal ? (
    <AuthContainer style={{ width: 'auto' }}>{props.children}</AuthContainer>
  ) : (
    <StyledAuthWrapper>
      <Blob>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#FF7500"
            d="M28.8,17.5C21.3,29.6,-11.9,27.8,-21,14.8C-30.1,1.8,-15,-22.4,1.6,-21.5C18.2,-20.6,36.3,5.4,28.8,17.5Z"
            transform="translate(100 100)"
          />
        </svg>
      </Blob>
      <Grid container component="main" style={{ height: '100vh' }}>
        <ArtworkContainer onClick={() => history.push('/')} />
        <AuthContainerWrapper>
          <AuthContainer>{props.children}</AuthContainer>
        </AuthContainerWrapper>
      </Grid>
    </StyledAuthWrapper>
  );
};

export default AuthWrapper;
