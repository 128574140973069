import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Slide, Stack, Typography, Divider, Tooltip } from '@mui/material';
import { Close, Shuffle, ShuffleOn, ClearAll, PlaylistRemove, PlaylistPlay } from '@mui/icons-material';

import type { Active, DropAnimation } from '@dnd-kit/core';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { THEME } from 'utils/constants';
import { RoomContext } from 'views/Room/Room';

import PlaylistItem from './PlaylistItem';

import IPlayListProps, { IItemWrapperProps } from './types';
import { SortableList } from './style';
import { IVideoItem } from 'views/Room/socketEventTypes';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

const Playlist = (props: IPlayListProps) => {
  const { t } = useTranslation();
  const { roomRef, socketRef, currentUserSocketId } = useContext(RoomContext);
  const [playlistItems, setPlaylistItems] = useState<IVideoItem[]>([]);
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(() => playlistItems.find((item) => item.videoId === active?.id), [active, playlistItems]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [isShuffleOn, setShuffleOn] = useState(false);

  const establishedSocketEventsRef = useRef(false);

  useEffect(() => {
    // setUserList(roomRef.current?.users);
  }, [currentUserSocketId]);

  useEffect(() => {
    const socket = socketRef.current;

    setPlaylistItems(roomRef.current?.playlist ?? []);

    if (!establishedSocketEventsRef.current) {
      socket.on('addToPlaylist', async (newVideo) => setPlaylistItems((items) => [...items, newVideo]));

      socket.on('removeFromPlaylist', async (videoId) =>
        setPlaylistItems((items) => items.filter((item) => item.videoId !== videoId)),
      );

      socket.on('reorderPlaylist', async (data) => {
        setPlaylistItems((currentPlaylist) => {
          return arrayMove(currentPlaylist, data.oldIndex, data.newIndex);
        });
      });

      socket.on('updateShufflePlaylistState', async (isShuffleOn) => setShuffleOn(isShuffleOn));

      socket.on('clearAllPlaylistItems', async () => {
        roomRef.current.playlist = [];
        setPlaylistItems([]);
      });

      establishedSocketEventsRef.current = true;
    }

    // clean-up event listeners
    return () => {
      socket.off('addToPlaylist');
      socket.off('removeFromPlaylist');
      establishedSocketEventsRef.current = false;
    };
  }, [roomRef, socketRef]);

  const handleDragStart = useCallback((event) => {
    setActive(event.active);
  }, []);

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      if (!over || !active) {
        return;
      }
      if (active.id !== over.id) {
        setPlaylistItems((currentPlaylistItems) => {
          const oldIndex = currentPlaylistItems.findIndex((item) => item.videoId === active.id);
          const newIndex = currentPlaylistItems.findIndex((item) => item.videoId === over.id);
          socketRef.current?.emit('reorderPlaylist', { oldIndex, newIndex });
          return arrayMove(currentPlaylistItems, oldIndex, newIndex);
        });
      }
    },
    [socketRef],
  );

  const handleDragCancel = useCallback(() => {
    setActive(null);
  }, []);

  const ItemWrapper = (props: IItemWrapperProps) => {
    const { item } = props;

    const handlePlayNowBtnClick = useCallback(() => {
      socketRef.current?.emit('playSpecificPlaylistItem', item.videoId);
    }, [item]);

    const handleRemoveBtnClick = useCallback(() => socketRef.current?.emit('removeFromPlaylist', item.videoId), []);

    return (
      <PlaylistItem id={item.videoId} {...item}>
        <Stack flexDirection={'column'}>
          <Tooltip title={t('playlist.play_now')}>
            <IconButton size="medium" onClick={handlePlayNowBtnClick}>
              <PlaylistPlay sx={{ color: THEME.TEXT }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('playlist.remove')}>
            <IconButton size="medium" onClick={handleRemoveBtnClick}>
              <PlaylistRemove sx={{ color: THEME.TEXT }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </PlaylistItem>
    );
  };

  return (
    <Slide direction="up" in={props.isVisible} mountOnEnter unmountOnExit>
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          background: THEME.PRIMARY,
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between" p={2}>
            <Typography fontWeight="bold">{t('playlist.title')}</Typography>
            <div>
              <Tooltip title={t('close')}>
                <IconButton onClick={() => props.toggle(false)} size="medium">
                  <Close style={{ color: THEME.TEXT }} />
                </IconButton>
              </Tooltip>
            </div>
          </Stack>
          <Divider sx={{ mb: 2 }} />
          <Stack flexDirection={'row'} ml={2} gap={2}>
            <Tooltip title={t('playlist.clear')}>
              <IconButton onClick={() => socketRef.current?.emit('clearAllPlaylistItems')} size="medium">
                <ClearAll style={{ color: THEME.TEXT }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('playlist.shuffle')}>
              <IconButton
                onClick={() => socketRef.current?.emit('updateShufflePlaylistState', !isShuffleOn)}
                size="medium"
              >
                {isShuffleOn ? <ShuffleOn style={{ color: THEME.TEXT }} /> : <Shuffle style={{ color: THEME.TEXT }} />}
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider sx={{ pt: 2 }} />
          {playlistItems.length === 0 ? (
            <Typography textAlign={'center'} p={2} fontStyle="italic">
              {t('playlist.empty')}
            </Typography>
          ) : (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
            >
              <SortableContext items={playlistItems.map((item) => item.videoId)}>
                <SortableList role="application">
                  <Typography>{t('playlist.up_next')}</Typography>
                  {playlistItems && playlistItems.map((item) => <ItemWrapper key={item.videoId} item={item} />)}
                </SortableList>
              </SortableContext>
              <DragOverlay dropAnimation={dropAnimationConfig}>
                {activeItem ? <ItemWrapper item={activeItem} /> : null}
              </DragOverlay>
            </DndContext>
          )}
          <Divider />
        </div>
      </div>
    </Slide>
  );
};

export default Playlist;
