import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { styled as styledMUI } from '@mui/system';

import { Button, TextField, Tab } from '@mui/material';

import { THEME } from 'utils/constants';

export const LoadingContainer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgb(8, 17, 32);
  background-image: url('https://cdn.dribbble.com/users/563824/screenshots/3114148/good-1.gif');
  /*background-image: url("https://file.mockplus.com/image/2018/04/943d662b-25c9-42d6-9fd2-cc36d2ffab76.gif");*/
  background-repeat: no-repeat;
  background-position: center;
`;

export const PrimaryButton = styledMUI(Button)({
  'backgroundColor': THEME.ACCENT,
  'color': THEME.IMPORTANT_TEXT,
  'fontSize': '24px',
  'borderRadius': '16px',
  ':hover': {
    backgroundColor: THEME.ACCENT,
    filter: 'brightness(1.2)',
    boxShadow: '0 0 2rem rgba(255,255,255,0.1)',
  },
});

export const SecondaryButton = styledMUI(Button)({
  'color': THEME.IMPORTANT_TEXT,
  'border': `2px solid ${THEME.IMPORTANT_TEXT}`,
  'borderRadius': '16px',
  ':hover': {
    color: THEME.ACCENT,
    border: `2px solid ${THEME.ACCENT}`,
    boxShadow: '0 0 2rem rgba(255,255,255,0.1)',
  },
});

export const CenteredDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SubscribeButton = styledMUI(Button)({
  'overflow': 'hidden',
  'cursor': 'pointer',
  'transition': 'all 0.3s ease',

  '&::before': {
    content: "''",
    transform: 'skewX(-45deg)',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: `linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 100%
    )`,
    animation: 'shineAnimation 1.8s ease-out infinite',
    backgroundSize: '100%',
  },

  '&:hover': {
    transform: 'scale(1.06)',
    backgroundColor: THEME.ACCENT,
  },

  '@keyframes shineAnimation': {
    '0%': {
      left: '-150%',
    },
    '100%': {
      left: '150%',
    },
  },
});

export const NavItem = styled(Link)`
  font-family: Poppins;
  line-height: 2.5rem;
  transition: color 0.25s ease-in-out;
  display: inline;
  color: ${THEME.IMPORTANT_TEXT}; // #6aa5d2;
  text-decoration: none;
  padding: 1rem;
  &:hover,
  :focus {
    color: ${THEME.ACCENT}; //#417399;
  }
`;

export const StyledTextField = styledMUI(TextField)({
  'background': THEME.SECONDARY,
  'marginTop': '4px',
  'borderRadius': '0.5rem',
  '& input': {
    color: THEME.TEXT,
  },
  '& .MuiOutlinedInput-root': {
    'color': THEME.TEXT,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

export const DisabledTab = styledMUI(Tab)({
  'color': 'rgba(255,255,255,0.25)',
  '&:hover': {
    cursor: 'not-allowed',
  },
});

export const LoginModalWrapperStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
};

const dotFlashing = keyframes`
  0% {
    background-color: ${THEME.ACCENT};
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
`;
export const DotFlashing = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${THEME.ACCENT};
  color: ${THEME.ACCENT};
  animation: ${dotFlashing} 0.5s infinite linear alternate;
  animation-delay: 0.25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${THEME.ACCENT};
    color: ${THEME.ACCENT};
    animation: ${dotFlashing} 0.5s infinite alternate;
  }

  &::before {
    left: -15px;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    animation-delay: 0.5s;
  }
`;
