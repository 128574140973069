import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, IconButton, Badge, Stack, Popover } from '@mui/material';
import { NewReleasesOutlined, Inbox, Person, Explore } from '@mui/icons-material';

import { GlobalContext } from 'App';
import { requestEmailVerification } from 'api/auth';
import { THEME } from 'utils/constants';
import PremiumButton from 'components/common/PremiumButton';
import ProfileMenu from 'components/common/ProfileMenu';
import Logo from 'components/common/Logo';
import ConfirmationModal from 'components/common/ConfirmationModal';
import useRateLimiterHook from 'components/hooks/rateLimiterHook';

import RoomsPanel from './RoomsPanel';
import UploadsPanel from './UploadsPanel';
import AdminPanel from './AdminPanel';
import WhatsNewPanel from './WhatsNewPanel';
import NotificationsPanel from './NotificationsPanel';
import SupportPanel from './SupportPanel';
import { DashboardContentWrapper, DashboardNavbar, NavbarSection, DashboardContent } from './style';
import IDashboard from './types';

let showedVerifyEmailModalOnce = false;

let notificationCount = 1;

const Dashboard = (props: IDashboard) => {
  const { t } = useTranslation();
  const { userProfileData } = useContext(GlobalContext);

  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [notifMenuAnchor, setNotifMenuAnchor] = useState(null);
  const [newReleasesMenuAnchor, setNewReleasesMenuAnchor] = useState(null);

  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [showUnverifiedEmailWarningModal, setShowUnverifiedEmailWarningModal] = useState(false);

  const [retryAfterTime, setRetryAfterTime] = useRateLimiterHook();

  useEffect(() => {
    document.title = 'Synther';
  }, []);

  const fetchEmailVerification = useCallback(async () => {
    const response = await requestEmailVerification();
    if (response.status === 429) {
      setRetryAfterTime(Number(response.headers.get('Retry-After')));
    }
  }, [setRetryAfterTime]);

  useEffect(() => {
    if (!userProfileData.email_verified && !showedVerifyEmailModalOnce) {
      showedVerifyEmailModalOnce = true;
      setShowVerifyEmailModal(true);
    }
  }, [userProfileData]);

  return (
    <DashboardContentWrapper>
      <ConfirmationModal
        isVisible={showVerifyEmailModal}
        title={t('verify_email_address_title')}
        message={t('verify_email_address_message')}
        confirmText={
          retryAfterTime !== null
            ? t('try_again_in_seconds', { seconds: retryAfterTime })
            : t('resend_verification_email')
        }
        onConfirmCbk={fetchEmailVerification}
        isConfirmDisabled={retryAfterTime !== null}
        cancelText={t('not_now')}
        onCancelCbk={() => {
          setShowVerifyEmailModal(false);
          setShowUnverifiedEmailWarningModal(true);
        }}
      />
      <ConfirmationModal
        isVisible={showUnverifiedEmailWarningModal}
        title={t('unverified_email_notice_title')}
        message={t('unverified_email_notice_description')}
        confirmText={t('understood')}
        onConfirmCbk={() => setShowUnverifiedEmailWarningModal(false)}
        onCancelCbk={() => setShowUnverifiedEmailWarningModal(false)}
      />
      <DashboardNavbar>
        <NavbarSection>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
            <Logo size="small" removeLink={props.children == null} overrideLinkUrl="/app" />
          </Stack>
        </NavbarSection>
        <NavbarSection>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <IconButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => setNewReleasesMenuAnchor(e.currentTarget)}>
              <NewReleasesOutlined sx={{ color: THEME.TEXT }} />
            </IconButton>
            <IconButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (notificationCount > 0) {
                  notificationCount--;
                }
                setNotifMenuAnchor(e.currentTarget);
              }}
            >
              <Badge
                badgeContent={notificationCount}
                color="error"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Inbox sx={{ color: THEME.TEXT }} />
              </Badge>
            </IconButton>
            <PremiumButton onClick={() => alert('pay')}>Ultra</PremiumButton>
            <IconButton onClick={(e: any) => setProfileMenuAnchor(e.currentTarget)}>
              <Avatar
                alt="Account Avatar"
                src={userProfileData?.avatar}
                sx={{
                  width: '30px',
                  height: '30px',
                  backgroundColor: userProfileData?.avatar == null ? userProfileData.accent_color : 'none',
                }}
              />
            </IconButton>
          </Stack>
          <Popover
            anchorEl={newReleasesMenuAnchor}
            open={Boolean(newReleasesMenuAnchor)}
            onClose={() => setNewReleasesMenuAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <WhatsNewPanel />
          </Popover>
          <Popover
            anchorEl={notifMenuAnchor}
            open={Boolean(notifMenuAnchor)}
            onClose={() => setNotifMenuAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <NotificationsPanel />
          </Popover>
          <ProfileMenu anchorEl={profileMenuAnchor} setAnchorEl={setProfileMenuAnchor} />
        </NavbarSection>
      </DashboardNavbar>
      <DashboardContent>{props.children || <RoomsPanel />}</DashboardContent>
    </DashboardContentWrapper>
  );
};

export default Dashboard;
