import { useEffect } from 'react';
import { Typography, Container } from '@mui/material';

import { ReactComponent as NotFound404SVG } from 'assets/svg/404/404.svg';
import { ReactComponent as SpaceSVG } from 'assets/svg/404/space.svg';
import { ReactComponent as CorgySVG } from 'assets/svg/404/corgy.svg';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import { useTranslation } from 'react-i18next';

import { Wrapper, SvgContainer, FooterContainer, SpaceSvgWrapper, CorgySvgWrapper } from './style';

const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'Synther | Page Not Found';
  }, []);

  return (
    <>
      <Header />
      <Wrapper>
        <SvgContainer>
          <NotFound404SVG />
          <SpaceSvgWrapper>
            <SpaceSVG />
          </SpaceSvgWrapper>
          <CorgySvgWrapper>
            <CorgySVG width="250" height="250" />
          </CorgySvgWrapper>
        </SvgContainer>
        <FooterContainer>
          <Container maxWidth="md" sx={{ p: 2 }}>
            <Typography variant="h4" align="center">
              {t('page_no_exists')}
            </Typography>
            <Typography variant="h6" align="center">
              {t('page_exists')}
            </Typography>
          </Container>
        </FooterContainer>
      </Wrapper>
      <Footer />
    </>
  );
};

export default NotFound;
