import { useState, useMemo, memo, useRef, useEffect } from 'react';
import { IconButton, Popover } from '@mui/material';

import Twemoji from 'components/common/Twemoji';

import EmojiSelector from './EmojiSelector';

import { EmojiWrapper } from './style';

const EMOJI_LIST = ['🥶', '🤣', '😁', '😱', '😬', '🤯', '🥵', '🤡', '💀', '😲', '👀'];

const EmojiButton = () => {
  const [currentEmoji, setCurrentEmoji] = useState(0);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [showEmojiSelector, setShowEmojiSelector] = useState(false);

  const iconButtonRef = useRef<HTMLButtonElement>(null);

  const EmojiSelectorMemo = useMemo(() => <EmojiSelector closeEmojiSelector={() => setShowEmojiSelector(false)} />, []);

  useEffect(() => {
    // load emojis in the background on EmojiButton mount
    if (iconButtonRef.current) {
      setAnchorEl(iconButtonRef.current);
    }
  }, []);

  return (
    <EmojiWrapper>
      <IconButton
        ref={iconButtonRef}
        size="small"
        onClick={(e) => setShowEmojiSelector(true)} // setAnchorEl(e.currentTarget)}
        onMouseEnter={() =>
          setCurrentEmoji((currentEmojiValue) => {
            if (currentEmojiValue === EMOJI_LIST.length - 1) {
              return 0;
            }
            return currentEmojiValue + 1;
          })
        }
      >
        <Twemoji emoji={EMOJI_LIST[currentEmoji]} />
      </IconButton>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          onClose={() => setShowEmojiSelector(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          // css to hide the popover when the EmojiSelector is not supposed to be shown
          sx={{ display: showEmojiSelector ? 'block' : 'none' }}
          // reason we don't use open prop is because it will re-render the EmojiSelector component every time the state changes
          open
        >
          {EmojiSelectorMemo}
        </Popover>
      )}
    </EmojiWrapper>
  );
};

export default memo(EmojiButton);
