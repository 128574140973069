import styled from 'styled-components';

export const CollapsiblePanelHeaderWrapper = styled.button`
  background: none;
  border: none;
  border-radius: 0;
  color: inherit;
  font: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
