import { PremiumTypes, AccountFlags } from 'utils/constants';

export interface IUserProfileData {
  is_guest: boolean;
  username: string;
  accent_color: string;
  email?: string;
  email_verified?: boolean;
  avatar?: string;
  locale?: string;
  account_flags?: AccountFlags[];
  premium_type?: PremiumTypes;
}

export const loginFunc = async (username: string, password: string) => {
  const payload = {
    username,
    password,
  };
  const response = await fetch('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Origin': '*',
    },
    body: JSON.stringify(payload),
  });
  return response;
};

export const logoutUser = async () => {
  const response = await fetch('/api/logout', {
    method: 'POST',
    credentials: 'include',
  });
  return response.status;
};

interface IRegisterFields {
  email: string;
  username: string;
  password: string;
  dob: { month: string; day: number; year: number };
  agreedToTerms: boolean;
  allowExtraEmails: boolean;
}

interface IRegisterFormErrors {
  email?: string;
  username?: string;
  password?: string;
  dob?: string;
  agreedToTerms?: string;
}

type IRegisterResponseData = { type: 'success'; data: IUserProfileData } | { type: 'error'; data: IRegisterFormErrors };

/**
 * Sends a request to the server to register a user
 * @protocal POST
 */
export const registerUser = async (payload: IRegisterFields) => {
  // sent post request to server to register
  const response = await fetch('/api/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  return data as IRegisterResponseData;
};

export const isUserAuthenticated = async (): Promise<IUserProfileData | null> => {
  const response = await fetch('/api/auth', {
    method: 'GET',
    credentials: 'include',
  });
  return response.status === 200 ? await response.json() : null;
};

export const requestEmailVerification = async () => {
  const response = await fetch('/api/auth/request-email-verification', {
    method: 'POST',
    credentials: 'include',
  });
  return response;
};
