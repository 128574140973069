import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { Tabs, Tab, Typography, Box, Avatar, Button, Stack } from '@mui/material';

import { GlobalContext } from 'App';
import { THEME } from 'utils/constants';
import { SecondaryButton } from 'style/common';
import ConfirmationModal from 'components/common/ConfirmationModal';

import {
  SettingsWrapper,
  SettingsContainer,
  ProfilePreview,
  ProfileBannerPreview,
  ProfileAvatarPreview,
  ProfileInfo,
  ProfileParameter,
  StyledProfilePreview,
} from './style';
import { hideEmail } from './utils';

const TabPanel = (props: { children?: JSX.Element; index: number; value: number }) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const SettingsPanel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoggedIn, userProfileData } = useContext(GlobalContext);
  const [value, setValue] = useState(0);
  const [brightnessAvatar, setBrightnessAvatar] = useState(1);

  const [revealEmail, setRevealEmail] = useState(false);

  const [showAccountDisableWarningModal, setShowAccountDisableWarningModal] = useState(false);
  const [showAccountDeletionWarningModal, setShowAccountDeletionWarningModal] = useState(false);

  useEffect(() => {
    document.title = 'Synther | Settings';
  }, []);

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  const handleAvatarHover = (brightness: number) => {
    setBrightnessAvatar(brightness);
  };

  return (
    <SettingsWrapper>
      <ConfirmationModal
        isVisible={showAccountDeletionWarningModal}
        title={t('generic_confirm_title')}
        message={t('settings.account_section.delete_section.delete_account_warning')}
        cancelText={t('cancel')}
        confirmByTextFieldValue="DELETE"
        onConfirmCbk={() => {
          setShowAccountDeletionWarningModal(false);
          // TODO send api request to delete account
          // TODO logout user
        }}
        onCancelCbk={() => setShowAccountDeletionWarningModal(false)}
      />
      <ConfirmationModal
        isVisible={showAccountDisableWarningModal}
        title={t('generic_confirm_title')}
        message={t('settings.account_section.delete_section.disable_account_warning')}
        cancelText={t('cancel')}
        onConfirmCbk={() => {
          setShowAccountDisableWarningModal(false);
          // TODO send api request to disable account
          // TODO logout user
        }}
        onCancelCbk={() => setShowAccountDisableWarningModal(false)}
      />
      <SettingsContainer>
        <Typography variant="h3" fontWeight="bold">
          {t('settings.title')}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: THEME.ACCENT }}>
            <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
              <Tab label="Account" />
              <Tab label="Billing" />
              <Tab label="Security & Privacy" />
              <Tab label="Notifications" />
              <Tab label="Linked Accounts" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <>
              <Typography variant="h6">{t('settings.account_section.profile_section.title')}</Typography>
              <Typography variant="subtitle2">{t('settings.account_section.profile_section.description')}</Typography>
              <ProfilePreview>
                <ProfileBannerPreview />
                <ProfileAvatarPreview>
                  <Avatar
                    alt="Account Avatar"
                    src={userProfileData.avatar}
                    sx={{
                      width: '125px',
                      height: '125px',
                      border: '8px solid #1f1f23',
                      cursor: 'pointer',
                      filter: 'brightness(' + brightnessAvatar + ')',
                      backgroundColor: userProfileData?.avatar == null ? userProfileData.accent_color : 'none',
                    }}
                    onMouseEnter={() => handleAvatarHover(0.6)}
                    onMouseLeave={() => handleAvatarHover(1)}
                  />
                  <Typography
                    variant="subtitle2"
                    color={THEME.IMPORTANT_TEXT}
                    fontWeight="bold"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      pointerEvents: 'none',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      visibility: brightnessAvatar === 1 ? 'hidden' : 'visible',
                    }}
                    textTransform="uppercase"
                  >
                    {t('settings.account_section.profile_section.change_avatar')}
                  </Typography>
                </ProfileAvatarPreview>
                <ProfileInfo>
                  <ProfileParameter>
                    <div>
                      <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
                        {t('username')}
                      </Typography>
                      <Typography variant="body2" color={THEME.IMPORTANT_TEXT}>
                        {userProfileData.username}
                      </Typography>
                    </div>
                    <SecondaryButton sx={{ fontSize: '14px' }}>{t('edit')}</SecondaryButton>
                  </ProfileParameter>

                  <ProfileParameter>
                    <div>
                      <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
                        {t('password')}
                      </Typography>
                      <Typography variant="body2" color={THEME.IMPORTANT_TEXT}>
                        *****************
                      </Typography>
                    </div>
                    <SecondaryButton sx={{ fontSize: '14px' }}>{t('edit')}</SecondaryButton>
                  </ProfileParameter>
                  <ProfileParameter>
                    <div>
                      <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
                        {t('email')}
                      </Typography>
                      <Stack flexDirection="row" alignItems="center" gap={2}>
                        <Typography variant="body2" color={THEME.IMPORTANT_TEXT}>
                          {revealEmail ? userProfileData.email : hideEmail(userProfileData.email)}
                        </Typography>
                        <Button onClick={() => setRevealEmail((isRevealed) => !isRevealed)}>
                          {revealEmail ? t('hide') : t('reveal')}
                        </Button>
                      </Stack>
                    </div>
                    <SecondaryButton sx={{ fontSize: '14px' }}>{t('edit')}</SecondaryButton>
                  </ProfileParameter>
                  <ProfileParameter>
                    <div>
                      <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
                        {t('settings.account_section.profile_section.phone_number')}
                      </Typography>
                    </div>
                    <SecondaryButton sx={{ fontSize: '14px' }}>{t('edit')}</SecondaryButton>
                  </ProfileParameter>
                </ProfileInfo>
              </ProfilePreview>
              <Typography variant="h6" style={{ marginTop: '1rem' }}>
                {t('settings.account_section.delete_section.title')}
              </Typography>
              <Typography variant="subtitle2">{t('settings.account_section.delete_section.description')}</Typography>
              <StyledProfilePreview>
                <Stack direction="row" spacing={2}>
                  <Button color="error" variant="contained" onClick={() => setShowAccountDisableWarningModal(true)}>
                    {t('settings.account_section.delete_section.disable_account')}
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    sx={{ color: THEME.IMPORTANT_TEXT }}
                    onClick={() => setShowAccountDeletionWarningModal(true)}
                  >
                    {t('settings.account_section.delete_section.delete_account')}
                  </Button>
                </Stack>
              </StyledProfilePreview>
            </>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <>{t('billing')}</>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <>
              <Typography variant="h6">{t('security')}</Typography>
              <Typography variant="subtitle2">{t('security_note')}</Typography>
              <StyledProfilePreview>
                <Typography variant="subtitle2">{t('password')}</Typography>
                <Typography variant="subtitle2">{t('two_factor_athentication')}</Typography>
              </StyledProfilePreview>
              <Typography variant="h6" style={{ marginTop: '1rem' }}>
                {t('privacy')}
              </Typography>
              <Typography variant="subtitle2">{t('adjust_privacy')}</Typography>
              <StyledProfilePreview>
                <Typography variant="subtitle2">{t('safe_file_sharing')}</Typography>
                <Typography variant="subtitle2">{t('who_adds_friend')}</Typography>
                <Typography variant="subtitle2">{t('blocked_users')}</Typography>
              </StyledProfilePreview>
            </>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <>{t('notifications')}</>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <>{t('linked_accounts')}</>
          </TabPanel>
        </Box>
      </SettingsContainer>
    </SettingsWrapper>
  );
};

export default SettingsPanel;
