import { useMemo } from 'react';
import twemoji from 'twemoji';

interface IEmoji {
  emoji: string;
}

const EMOJI_CDN_PREFIX_URL = 'https://cdn.jsdelivr.net/gh/jdecked/twemoji@15.1.0/assets/72x72';

export const getEmojiURL = (emoji: string) => {
  const parsedURL = twemoji.parse(emoji).match(/src="([^"]+)"/);

  if (!parsedURL) {
    return '';
  }

  const url = parsedURL[1].split('/'); // get file name
  const fileName = url[url.length - 1];
  return `${EMOJI_CDN_PREFIX_URL}/${fileName}`;
};

const Twemoji = (props: IEmoji) => {
  const url = useMemo(() => getEmojiURL(props.emoji), [props.emoji]);

  return <img className="twemoji" alt={props.emoji} draggable="false" src={url} />;
};

export default Twemoji;
