import { createContext, useContext, useState, useRef, useEffect, useMemo } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  AddCircle,
  SettingsSharp,
  FormatBoldOutlined,
  FormatItalicOutlined,
  StrikethroughSOutlined,
} from '@mui/icons-material';

import { EditorContent, useEditor, Editor, BubbleMenu, Extension } from '@tiptap/react';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import Emoji, { emojis } from '@tiptap-pro/extension-emoji';

import { GlobalContext } from 'App';
import { THEME } from 'utils/constants';
import useClickOutsideComponent from 'components/common/useClickOutsideComponent';
import { getEmojiURL } from 'components/common/Twemoji/Twemoji';

import { RoomContext } from 'views/Room/Room';

import EmojiButton from './EmojiButton';
import { Wrapper, BubbleMenuWrapper } from './style';

interface IChatTextFieldContext {
  /**
   * Function to modify the value of the message that the user crafted
   */
  // setMessage: React.Dispatch<React.SetStateAction<string>>;
  editor: Editor;
  /**
   * The position (index) of where the cursor is selected
   */
  cursorPosition: { start: number; end: number };
}

export const ChatTextFieldContext = createContext<IChatTextFieldContext>(null);

const ChatTextField = () => {
  const { socketRef } = useContext(RoomContext);
  const { userProfileData, invokeAuthenticatedCallback } = useContext(GlobalContext);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Send a message',
      }),
      Extension.create({
        addKeyboardShortcuts() {
          return {
            Enter: () => {
              socketRef.current.emit('sendMessage', editor.getText());
              editor.commands.clearContent();
              return true;
            },
          };
        },
        onFocus() {
          setFocused(true);
        },
      }),
      Emoji.configure({
        forceFallbackImages: true,
        emojis: emojis.map((emoji) => {
          const url = getEmojiURL(emoji.emoji);
          if (url) {
            return {
              ...emoji,
              fallbackImage: url,
            };
          }
          return emoji;
        }),
        enableEmoticons: true,
      }),
    ],
    content: '',
  });

  const wrapperRef = useRef(null);
  const textAreaRef = useRef<HTMLDivElement>(null);
  const [isFocused, setFocused] = useState(false);

  /**
   * The cursor position relative to the message state
   */
  const [cursorPosition, setCursorPosition] = useState({ start: 0, end: 0 });
  const didUserChangeCursorPositionRef = useRef(false);

  useClickOutsideComponent(wrapperRef, () => setFocused(false));

  useEffect(() => {
    const getSelectionInfo = () => {
      if (textAreaRef.current == null) {
        return;
      }

      const selection = document.getSelection();
      const newCursorPosition = { start: 0, end: 0 };

      const incrementByContainerType = (element: HTMLElement, amount?: number) => {
        if (element.dataset.type === 'text') {
          return amount ?? element.textContent.length;
        } else if (element.dataset.type === 'emoji') {
          return amount ?? 2;
        }
      };

      const isEqualNode = (node: Node, element: HTMLElement) =>
        (element.dataset.type === 'text' &&
          node.nodeType === Node.TEXT_NODE &&
          node.textContent === element.textContent) ||
        (element.dataset.type === 'emoji' &&
          node.nodeType === Node.ELEMENT_NODE &&
          (node as HTMLElement).querySelector('img')?.getAttribute('alt') ===
            element.querySelector('img').getAttribute('alt'));

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        let lookingForStartPosition = true;
        for (let i = 0; i < textAreaRef.current.children.length; i++) {
          const currentNode = textAreaRef.current.children[i] as HTMLElement;

          if (lookingForStartPosition) {
            if (isEqualNode(range.startContainer, currentNode)) {
              newCursorPosition.end = newCursorPosition.start;
              newCursorPosition.start += incrementByContainerType(currentNode, range.startOffset);
              lookingForStartPosition = false;
            } else {
              newCursorPosition.start += incrementByContainerType(currentNode);
            }
          }

          if (!lookingForStartPosition) {
            if (isEqualNode(range.endContainer, currentNode)) {
              newCursorPosition.end += incrementByContainerType(currentNode, range.endOffset);
              break;
            } else {
              newCursorPosition.end += incrementByContainerType(currentNode);
            }
          }
        }
      }

      if (newCursorPosition.end < newCursorPosition.start) {
        return;
      }
      didUserChangeCursorPositionRef.current = true;
      setCursorPosition(newCursorPosition);
    };

    // add an event listener for the 'selectionchange' event
    document.addEventListener('selectionchange', getSelectionInfo);

    // clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('selectionchange', getSelectionInfo);
    };
  }, []);

  useEffect(() => {
    if (cursorPosition.end < cursorPosition.start) {
      return;
    }
    /**
     * Confirm that we are programmatically changing the cursor position and not due to the user
     */
    if (didUserChangeCursorPositionRef.current) {
      didUserChangeCursorPositionRef.current = false;
      return;
    }

    const selection = document.getSelection();
    const range = document.createRange();
    // find the start and node that the selection should be based on currentPosition state
    let index = 0;
    for (let i = 0; i < textAreaRef.current?.childNodes.length; i++) {
      const currentNode = textAreaRef.current?.childNodes[i];
      const nodeType = currentNode.nodeType; // currentNode.dataset.type;
      if (nodeType === Node.TEXT_NODE) {
        index += currentNode.textContent.length;
      } else if (nodeType === Node.ELEMENT_NODE) {
        index += 1;
      }
      try {
        // reached the start node
        if (cursorPosition.start < index) {
          // calculate the offset
          const offset = index - cursorPosition.start;
          range.setStart(currentNode, offset);
        }
        // reached the end node
        if (cursorPosition.end < index) {
          // calculate the offset
          const offset = index - cursorPosition.end;
          range.setEnd(currentNode, offset);
        }
      } catch (e) {
        console.error(e);
      }
    }
    selection.removeAllRanges(); // remove current selection
    selection.addRange(range);
    textAreaRef.current?.focus();
  }, [cursorPosition]);

  const EmojiButtonMemo = useMemo(
    () => (
      <ChatTextFieldContext.Provider value={{ editor, cursorPosition }}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <EmojiButton />
          <IconButton size="small" onClick={(e) => invokeAuthenticatedCallback(null, () => e.preventDefault())}>
            <SettingsSharp fontSize="small" sx={{ color: THEME.TEXT }} />
          </IconButton>
        </Stack>
      </ChatTextFieldContext.Provider>
    ),
    [editor, cursorPosition, invokeAuthenticatedCallback],
  );

  return (
    <Wrapper
      ref={wrapperRef}
      style={{ boxShadow: isFocused ? `inset 0 -2px 0 ${THEME.ACCENT}` : 'inset 0 -2px 0 rgba(0, 0, 0, 0.42)' }}
    >
      <Box pl={2} pr={2} pt={1} pb={1}>
        <Typography
          color={isFocused ? THEME.ACCENT : THEME.IMPORTANT_TEXT}
          variant="caption"
          fontWeight="bold"
          sx={{ userSelect: 'none', transition: 'color 0.1s' }}
        >
          Chat
        </Typography>
        <Stack direction="row" alignItems="center">
          <Box>
            <input id="upload-attachment" multiple type="file" hidden />
            <label htmlFor="upload-attachment">
              <IconButton
                size="small"
                component="span"
                onClick={(e) => invokeAuthenticatedCallback(null, () => e.preventDefault())}
              >
                <AddCircle fontSize="small" sx={{ color: THEME.TEXT }} />
              </IconButton>
            </label>
          </Box>
          <Box
            id="chat-textfield"
            flex={1}
            pl={1}
            pr={1}
            maxWidth={'20rem'}
            minHeight={'2rem'}
            maxHeight={'200px'}
            sx={{ overflow: 'auto' }}
          >
            {/* <div style={{ position: 'absolute', cursor: 'text' }} onClick={focusOnTextArea}>
              {message === '' && (
                <Typography variant="body2" color={THEME.TEXT_DARK} sx={{ userSelect: 'none' }}>
                  Send a message
                </Typography>
              )}
            </div> */}
            <Typography component="span" variant="body2">
              <EditorContent editor={editor}>
                <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
                  <BubbleMenuWrapper>
                    <IconButton
                      onClick={() => editor.chain().focus().toggleBold().run()}
                      // className={editor.isActive('bold') ? 'is-active' : ''}
                    >
                      <FormatBoldOutlined sx={{ color: '#FFF' }} />
                    </IconButton>
                    <IconButton
                      onClick={() => editor.chain().focus().toggleItalic().run()}
                      // className={editor.isActive('italic') ? 'is-active' : ''}
                    >
                      <FormatItalicOutlined sx={{ color: '#FFF' }} />
                    </IconButton>
                    <IconButton
                      onClick={() => editor.chain().focus().toggleStrike().run()}
                      // className={editor.isActive('strike') ? 'is-active' : ''}
                    >
                      <StrikethroughSOutlined sx={{ color: '#FFF' }} />
                    </IconButton>
                  </BubbleMenuWrapper>
                </BubbleMenu>
              </EditorContent>
            </Typography>
          </Box>
          {EmojiButtonMemo}
        </Stack>
      </Box>
    </Wrapper>
  );
};

export default ChatTextField;
