import { useEffect } from 'react';
import { getCookieValue } from 'utils/commonUtils';
import { useHistory, useParams, RouteComponentProps } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { IServerEvents, IClientEvents } from './socketEventTypes';

const useSocket = (
  socketRef: React.MutableRefObject<Socket<IClientEvents, IServerEvents>>,
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const history = useHistory<RouteComponentProps>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const socket: Socket<IClientEvents, IServerEvents> = io(
      process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:8080',
      {
        query: { roomId: id },
        transports: ['websocket'],
        withCredentials: true,
        auth: {
          token: getCookieValue('token'),
        },
      },
    );

    socketRef.current = socket;

    socketRef.current.on('connection', async () => {
      setLoaded(true);
      socketRef.current.emit('joinRoom');
    });

    socketRef.current.on('redirect', async (url) => history.push(url));

    return () => {
      socket.disconnect();
    };
  }, [id, history, setLoaded]);
};

export default useSocket;
