import { createContext } from 'react';

import { MediaType } from '../socketEventTypes';

export interface IVideoPlayerContext {
  changeVideoSource: (newVideoSource: string, newMediaType?: MediaType) => void;
}

const VideoPlayerContext = createContext<IVideoPlayerContext>(null);
export default VideoPlayerContext;
