import styled from 'styled-components';

import { THEME } from 'utils/constants';

import ArtworkImg from 'assets/auth-page-bg.jpg';

export const StyledAuthWrapper = styled.div`
  background-color: ${THEME.ACCENT};
`;

export const AuthContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 1;
`;

export const AuthContainer = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${THEME.PRIMARY};
  padding: 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: auto;
  width: 40%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  @media screen and (max-width: 580px) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
`;

export const FormItem = styled.div`
  padding-top: 1rem;
`;

export const Blob = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 30vmax;
  z-index: 0;
  animation: move 10s ease-in-out infinite;
  transform-origin: 50% 50%;
  @keyframes move {
    0% {
      transform: scale(1) translate(10px, 30px);
    }
    38% {
      transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
    }
    40% {
      transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
    }
    78% {
      transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
    }
    80% {
      transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
    }
    100% {
      transform: scale(1) translate(10px, -30px);
    }
  }
`;

export const ArtworkContainer = styled.div`
  width: 30%;
  background-image: url(${ArtworkImg});
  background-repeat: no-repeat;
  background-color: ${THEME.PRIMARY};
  background-size: contain;
  background-position: center center;
  box-shadow: rgb(0 0 0 / 14%) 0px 0px 9px 7px, rgb(35 33 33 / 8%) 10px 13px 10px 6px;
  max-width: 30rem;
  z-index: 1;
  cursor: pointer;
  @media screen and (max-width: 1280px) {
    display: none;
  }
`;
