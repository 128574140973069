import styled from 'styled-components';

import { THEME } from 'utils/constants';

export const DashboardContentWrapper = styled.div`
  background-color: ${THEME.PRIMARY};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DashboardNavbar = styled.div`
  background-color: ${THEME.SECONDARY};
  width: 100%;
  min-height: 4rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0 2rem;
  box-sizing: border-box;
`;

export const NavbarSection = styled.div`
  display: flex;
`;

export const DashboardContent = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 0;
  max-height: 100%;
  display: flex;
`;
