import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 14px;
  width: 100%;
  margin-top: 2rem;
  z-index: 1;
`;
