import styled from 'styled-components';

import { TextField, ListItem } from '@mui/material';

import { hexToRGBA } from 'utils/commonUtils';
import { THEME } from 'utils/constants';

export const ShareModal = styled.div`
  width: 500px;
  background-color: ${hexToRGBA(THEME.PRIMARY, 0.4)};
  border-radius: 1rem;
`;

export const StyledListItem = styled(ListItem)({
  flexDirection: 'column',
  alignItems: 'stretch !important',
});

export const StyledTextField = styled(TextField)({
  '& label.MuiInputLabel-filled': {
    color: THEME.TEXT,
    fontSize: '14px',
  },
  '& textarea.MuiFilledInput-inputMultiline': {
    color: '#FFF',
    fontSize: '14px',
    width: 'calc(100% - 4rem)',
  },
  '& label.Mui-focused': {
    color: THEME.TEXT,
  },
  '& div.MuiFilledInput-underline:after': {
    borderBottom: '2px solid ' + THEME.SECONDARY,
  },
});
