import styled from 'styled-components';

import { Box } from '@mui/material';

import { THEME } from 'utils/constants';

export const HeaderWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  z-index: 1000;
  position: absolute;
`;

export const HeaderContainer = styled(Box)`
  padding: 8px 10rem;
  background-color: ${THEME.SECONDARY};
  box-shadow: 0 0 10px 0 ${THEME.PRIMARY};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    margin: 0;
  }
  @media only screen and (max-width: 1180px) {
    justify-content: space-between;
    padding: 8px 8px;
  }
`;

export const NavLinkWrapper = styled(Box)`
  @media only screen and (max-width: 1180px) {
    display: none;
  }
`;
