import styled from 'styled-components';

export const GuestLoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
  height: 100%;
  margin-bottom: 10rem;
`;

export const TermsContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 1rem;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
`;

export const GuestLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
  height: 100%;
  margin-bottom: 10rem;
`;
