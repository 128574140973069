export interface IYouTubeLink {
  videoId: string;
  title: string;
  dateTimestamp: number;
  numOfviews: number;
  uploader: string;
  description?: string;
}

export const fetchYoutubeTrendingVideos = async (): Promise<IYouTubeLink[]> => {
  const response = await fetch(`/api/youtube/trending`);
  const data = await response.json();
  return data as IYouTubeLink[];
};
