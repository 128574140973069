import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, InputAdornment, IconButton, Link, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { red } from '@mui/material/colors';

import { GlobalContext } from 'App';
import { StyledTextField, PrimaryButton } from 'style/common';
import { THEME } from 'utils/constants';
import { KeyType } from 'utils/commonTypes';
import { IUserProfileData, loginFunc } from 'api/auth';
import AuthWrapper from 'components/AuthWrapper';
import { FormItem } from 'components/AuthWrapper/style';
import ConfirmationModal from 'components/common/ConfirmationModal';

import { LoginFooter, NeedAccountContainer } from './style';
import ILogin from './types';

import { useTranslation } from 'react-i18next';

const VALID_USERNAME_REGEX = /^[\w-]+$/;
const VALID_EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

const Login = (props: ILogin) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setUserProfileData, isFullyLoggedIn } = useContext(GlobalContext);

  if (isFullyLoggedIn) {
    history.push('/app');
  }

  const username = useRef(null),
    password = useRef(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [passErrorMessage, setPassErrorMessage] = useState(null);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const [isPasswordResetModalVisible, setPasswordResetModalVisible] = useState(false);

  useEffect(() => {
    document.title = 'Synther | Login';
  }, []);

  const handleSubmit = async () => {
    let isAnyInvalid = false;

    if (username.current === '') {
      setEmailErrorMessage(t('This field is required'));
      isAnyInvalid = true;
    }

    if (
      username.current !== '' &&
      !VALID_EMAIL_REGEX.test(username.current) &&
      !VALID_USERNAME_REGEX.test(username.current)
    ) {
      setEmailErrorMessage(t('login_or_pass_invalid'));
      isAnyInvalid = true;
    }

    if (password.current === '') {
      setPassErrorMessage(t('This field is required'));
      isAnyInvalid = true;
    }

    if (isAnyInvalid) {
      return;
    }

    setIsAuthenticating(true);
    setEmailErrorMessage(null);
    setPassErrorMessage(null);
    try {
      const response = await loginFunc(username.current, password.current);
      if (response.status === 200) {
        const userProfileData: IUserProfileData = await response.json();
        setUserProfileData(userProfileData);
        if (props.onLoginCbk) {
          props.onLoginCbk();
        } else {
          history.push('/app');
        }
      } else {
        setEmailErrorMessage(t('login_or_pass_invalid'));
        setPassErrorMessage(t('login_or_pass_invalid'));
      }
      setIsAuthenticating(false);
    } catch (error) {
      console.error(error);
      setIsAuthenticating(false);
    }
  };

  const toggleVisibility = () => setPasswordVisible((currentPasswordVisible) => !currentPasswordVisible);

  const handleKeyDown = (e) => {
    const key = e.key as KeyType;
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <AuthWrapper isModal={props.onLoginCbk != null}>
      <>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
          }}
          align="center"
        >
          {props.message ? props.message : 'Welcome back!'}
        </Typography>
        <FormItem>
          <Typography variant="button" fontWeight="bold">
            {emailErrorMessage != null ? (
              <span style={{ color: red[400] }}>
                {t('email_or_username')} - <em style={{ fontSize: '10px' }}>{emailErrorMessage}</em>
              </span>
            ) : (
              <>
                {t('email_or_username')} <span style={{ color: red[400] }}>*</span>
              </>
            )}
          </Typography>
          <StyledTextField
            onChange={(event) => (username.current = event.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
            id="username"
            name="username"
            autoComplete="username"
            autoFocus
            onKeyDown={handleKeyDown}
            required
          />
        </FormItem>
        <FormItem>
          <Typography variant="button" fontWeight="bold">
            {passErrorMessage != null ? (
              <span style={{ color: red[400] }}>
                {t('password')} - <em style={{ fontSize: '10px' }}>{passErrorMessage}</em>
              </span>
            ) : (
              <>
                {t('password')} <span style={{ color: red[400] }}>*</span>
              </>
            )}
          </Typography>
          <StyledTextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleVisibility} size="large">
                    {isPasswordVisible ? (
                      <Visibility htmlColor={THEME.TEXT} />
                    ) : (
                      <VisibilityOff htmlColor={THEME.IMPORTANT_TEXT} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => (password.current = event.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
            type={isPasswordVisible ? 'text' : 'password'}
            autoComplete="current-password"
            onKeyDown={handleKeyDown}
            required
          />
        </FormItem>
        <FormItem>
          <PrimaryButton fullWidth variant="contained" onClick={handleSubmit}>
            {isAuthenticating ? <CircularProgress sx={{ color: THEME.IMPORTANT_TEXT }} /> : 'Login'}
          </PrimaryButton>
        </FormItem>
        <LoginFooter>
          <NeedAccountContainer>
            <Typography variant="body2" fontWeight="bold">
              {t('no_account')}
            </Typography>
            <Link
              onClick={() => history.push('/register')}
              underline="hover"
              style={{ cursor: 'pointer', color: THEME.ACCENT, paddingLeft: '0.25rem' }}
            >
              {t('register')}
            </Link>
          </NeedAccountContainer>
          <Link
            onClick={() => {
              console.log(username.current);
              if (username.current === '') {
                setEmailErrorMessage(t('This field is required'));
              } else {
                setPasswordResetModalVisible(true);
              }
            }}
            underline="hover"
            style={{ cursor: 'pointer', color: THEME.ACCENT }}
          >
            {t('forgot_password')}
          </Link>
        </LoginFooter>
        <ConfirmationModal
          isVisible={isPasswordResetModalVisible}
          title={'Forgot your password?'} // TODO translate
          message={
            "If there's an account associated with that email/username, we've sent a link to reset your password."
          } // TODO translate
          confirmText="Done"
          onConfirmCbk={() => setPasswordResetModalVisible(false)}
        />
      </>
    </AuthWrapper>
  );
};

export default Login;
