/**
 * Read more: https://developers.google.com/tenor/guides/response-objects-and-errors#content-formats
 */
type ContentFormat =
  | 'gif'
  | 'mediumgif'
  | 'tinygif'
  | 'nanogif'
  | 'mp4'
  | 'loopedmp4'
  | 'tinymp4'
  | 'nanomp4'
  | 'webm'
  | 'tinywebm'
  | 'nanowebm'
  | 'webp_transparent'
  | 'tinywebp_transparent'
  | 'nanowebp_transparent'
  | 'gif_transparent'
  | 'tinygif_transparent'
  | 'nanogif_transparent';

interface IMediaObject {
  /**
   * The URL for the media source
   */
  url: string;
  /**
   * The time in seconds for one loop of the content. If the content is static, the duration is set to 0.
   */
  duration: number;
  /**
   * Width and height of the media in pixels
   */
  dims: number[];
  /**
   * Size of the file in bytes
   */
  size: number;
}

export interface ITenorResponse {
  results: {
    /**
     * The unique identifier for the result
     */
    id: string;
    /**
     * The title of the tenor post
     */
    title: string;
    /**
     * A map of content formats to media objects
     */
    media_formats: Record<ContentFormat, IMediaObject>;
    /**
     * The unix timestamp of when the post was created
     */
    created: number;
    /**
     * Description of the content (recommended useage is for accessibility)
     */
    content_description: string;
    /**
     * The full URL to view the post on tenor.com
     */
    itemurl: string;
    /**
     * The short URL to view the post on tenor.com
     */
    url: string;
    /**
     * An array of tags for the post
     */
    tags: string[];
    /**
     * Comma-separated list to signify whether the content is a sticker or static image, has audio, or is any combination of these.
     * If sticker and static aren't present, then the content is a GIF. A blank flags field signifies a GIF without audio.
     */
    flags: string[];
    /**
     * Whether the post contains audio
     */
    hasaudio: boolean;
  }[];
  /**
   * A position identifier to use with the next API query (with the 'pos' parameter)
   * to retrieve the next set of results. If there are no further results, value will be an empty string.
   */
  next: string;
}

export const fetchTenorGifs = async (search: string) => {
  const response = await fetch(`/tenor?search=${search}`);
  const data = await response.json();
  return data as ITenorResponse;
};
