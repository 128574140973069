import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';

import { GlobalContext } from 'App';
import { PrimaryButton, SecondaryButton, NavItem } from 'style/common';
import Logo from 'components/common/Logo';

import { HeaderWrapper, HeaderContainer, NavLinkWrapper } from './style';
import { IHeader } from './types';

const Header = (props: IHeader) => {
  const { t } = useTranslation();
  const { isFullyLoggedIn } = useContext(GlobalContext);
  const history = useHistory();

  if (props.simplified) {
    return <Logo size="large" />;
  } else {
    return (
      <HeaderWrapper>
        <HeaderContainer>
          <Box p={1}>
            <Logo size="small" />
          </Box>
          <NavLinkWrapper p={1} flexGrow={1} style={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }}>
            <NavItem to="/ultra">{t('ultra')}</NavItem>
            <NavItem to="/support">{t('support')}</NavItem>
            <NavItem to="/terms">{t('terms_of_use')}</NavItem>
            <NavItem to="/privacy">{t('privacy_policy')}</NavItem>
          </NavLinkWrapper>
          <Box p={1}>
            {isFullyLoggedIn ? (
              <PrimaryButton variant="contained" onClick={() => history.push('/app')} sx={{ fontSize: '14px' }}>
                {t('open_synther')}
              </PrimaryButton>
            ) : (
              <>
                <SecondaryButton
                  variant="outlined"
                  onClick={() => history.push('/login')}
                  sx={{ fontSize: '14px', textTransform: 'none' }}
                >
                  {t('login')}
                </SecondaryButton>
                <PrimaryButton
                  variant="contained"
                  onClick={() => history.push('/register')}
                  sx={{ fontSize: '14px', marginLeft: '14px', textTransform: 'none' }}
                >
                  {t('sign_up')}
                </PrimaryButton>
              </>
            )}
          </Box>
        </HeaderContainer>
      </HeaderWrapper>
    );
  }
};

export default Header;
