import { useMemo } from 'react';
import {
  Skeleton,
  Stack,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Box,
  Tooltip,
} from '@mui/material';
import { PlaylistAdd } from '@mui/icons-material';

import { THEME } from 'utils/constants';
import { truncateText, convertTimestampToRelativeTimeString, formatNumber } from 'utils/commonUtils';
import { t } from 'i18next';

export interface IMediaProps {
  avatar: string;
  author: string;
  url: string;
  title: string;
  timestamp: number;
  imageURL: string;
  numOfViews: number;
  onCardClicked: (url: string) => void;
  onAddToPlaylist?: () => void;
}

const MediaCard = (props: IMediaProps) => {
  const { onCardClicked } = props;

  return (
    <Card
      sx={{ minHeight: '100%', width: 'auto', backgroundColor: THEME.TERTIARY, cursor: 'pointer' }}
      onClick={() => onCardClicked(props.url)}
    >
      <CardHeader
        avatar={<></>}
        action={
          props?.url && props.onAddToPlaylist ? (
            <Tooltip title={t('playlist.add')}>
              <IconButton
                aria-label="settings"
                sx={{ zIndex: 9999 }}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onAddToPlaylist();
                }}
              >
                <PlaylistAdd sx={{ color: THEME.IMPORTANT_TEXT }} />
              </IconButton>
            </Tooltip>
          ) : null
        }
        title={props?.author ?? <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={
          props?.timestamp || props?.numOfViews ? (
            <Typography variant="body2" component="p" sx={{ color: THEME.TEXT }}>
              <Stack flexDirection={'row'} divider={<div>•</div>} gap={1}>
                {props?.numOfViews && formatNumber(props.numOfViews)}
                {props?.timestamp && convertTimestampToRelativeTimeString(props.timestamp)}
              </Stack>
            </Typography>
          ) : (
            <Skeleton animation="wave" height={10} width="40%" />
          )
        }
      />
      {props?.imageURL ? (
        <CardMedia component="img" image={props.imageURL} alt="Nicola Sturgeon on a TED talk stage" />
      ) : (
        <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
      )}
      <CardContent>
        {props?.title ? (
          <Tooltip title={props.title}>
            <Typography variant="body2" component="p" sx={{ color: THEME.IMPORTANT_TEXT }}>
              {truncateText(props.title, 100, true)}
            </Typography>
          </Tooltip>
        ) : (
          <>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
          </>
        )}
      </CardContent>
    </Card>
  );
};

interface IVideoGridProps {
  data?: IMediaProps[];
}

export const VideoGrid = (props: IVideoGridProps) => {
  const nullArrayMemo = useMemo(() => new Array(20).fill(null), []);

  return (
    <Stack p={4} overflow={'auto'} alignItems={'center'}>
      <Grid container spacing={4}>
        {(props.data ?? nullArrayMemo).map((media: IMediaProps | null, index) => (
          <Grid key={index} xs={6} lg={4} xl={3} item>
            <MediaCard {...media} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </Stack>
  );
};

export default VideoGrid;
