import styled from 'styled-components';

import { THEME } from 'utils/constants';

export const SortableItem = styled.li`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  background-color: ${THEME.SECONDARY};
  box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
    0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box;
  list-style: none;
  position: relative;
  margin-left: 0.5rem;
  max-height: 100px;
  user-select: none;
`;

export const DragHandleBtn = styled.button`
  position: absolute;
  left: -30px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, grab);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:focus-visible {
    box-shadow: 0 0px 0px 2px #4c9ffe;
  }
`;

export const DragHandleSvg = styled.svg`
  flex: 0 0 auto;
  margin: auto;
  height: 100%;
  overflow: visible;
  fill: #919eab;
`;
