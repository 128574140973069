import styled from 'styled-components';

import { Box } from '@mui/material';

import { THEME } from 'utils/constants';

export const ChatboxWrapper = styled(Box)`
  flex-shrink: 0;
  z-index: 4;
`;

export const ChatboxContainer = styled.div`
  background-color: ${THEME.PRIMARY}; //rgb(31, 35, 38);
  height: 100%;
  border-left: 1px solid ${THEME.SECONDARY};
`;

export const ChatboxHeader = styled(Box)`
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 12px;
  box-shadow: 0 0 200px rgb(6 106 183 / 50%);
`;
