import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Button, Stack } from '@mui/material';
import {
  CloudUploadRounded,
  EmojiEmotionsRounded,
  RecommendRounded,
  TvRounded,
  LogoDevRounded,
  PublicRounded,
  DnsRounded,
  SupportAgentRounded,
  MoreHorizRounded,
  VideoCallRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import { THEME } from 'utils/constants';
import { CoreContainer, PurchaseUltraContainer, MoreToComeContainer } from './style';

const Ultra = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    document.title = 'Synther | Ultra';
  }, []);

  return (
    <>
      <Header />
      <CoreContainer>
        <Stack>
          <Typography
            variant="h4"
            pb={4}
            pl={2}
            color={THEME.IMPORTANT_TEXT}
            textTransform="uppercase"
            align="left"
            fontWeight="bold"
            fontFamily="Montserrat"
            sx={{
              maxWidth: '35rem',
              textShadow: '8px 8px 24px rgba(0, 0, 0, .5)',
              zIndex: 1,
            }}
          >
            {t('ultra_title')}
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            p={2}
            align="left"
            maxWidth="600px"
            sx={{
              textShadow: '1px 1px 24px rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            }}
          >
            {t('ultra_description')}
          </Typography>
        </Stack>
        <Stack>
          <PurchaseUltraContainer>
            <Typography variant="h3" fontWeight={'bold'} p={0} m={0}>
              {t('ultra')}
            </Typography>
            <Stack spacing={2} pt={2} pb={2}>
              <Stack flexDirection={'row'}>
                <CloudUploadRounded />
                <Typography pl={3} variant="subtitle1">
                  {t('ultra_features.file_sharing.title')}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <VideoCallRounded />
                <Typography pl={3} variant="subtitle1">
                  {t('ultra_features.upload_custom_content.title')}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <EmojiEmotionsRounded />
                <Typography pl={3}>{t('ultra_features.custom_emojis.title')}</Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <RecommendRounded />
                <Typography pl={3}>{t('ultra_features.super_reactions.selling_point')}</Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <TvRounded />
                <Typography pl={3}>{t('ultra_features.unlimited_rooms.title')}</Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <PublicRounded />
                <Typography pl={3}>{t('ultra_features.public_rooms.selling_point')}</Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <DnsRounded />
                <Typography pl={3}>{t('ultra_features.server_region.selling_point')}</Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <SupportAgentRounded />
                <Typography pl={3}>{t('ultra_features.priority_support.title')}</Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <LogoDevRounded />
                <Typography pl={3}>{t('ultra_features.beta_tester_badge.title')}</Typography>
              </Stack>
              <Stack flexDirection={'row'} pb={2}>
                <MoreHorizRounded />
                <Typography pl={3}>{t('ultra_features.many_more')}</Typography>
              </Stack>
              <Button variant="contained" sx={{ bgcolor: '#FFF' }} onClick={() => history.push('/app')}>
                <Typography variant="h6" fontWeight={'bold'} color="black">
                  {t('ultra_features.call_to_action')}
                </Typography>
              </Button>
            </Stack>
          </PurchaseUltraContainer>
        </Stack>
      </CoreContainer>
      <MoreToComeContainer>
        <Typography
          variant="h3"
          color={THEME.IMPORTANT_TEXT}
          textTransform="uppercase"
          align="center"
          fontWeight="bold"
          fontFamily="Montserrat"
          sx={{
            textShadow: '8px 8px 24px rgba(0, 0, 0, .5)',
            zIndex: 1,
          }}
        >
          ADDITIONAL DETAILS COMING SOON
        </Typography>
      </MoreToComeContainer>
      <Footer />
    </>
  );
};

export default Ultra;
