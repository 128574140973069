import styled from 'styled-components';

import { THEME } from 'utils/constants';

export const Container = styled.div`
  width: 400px;
  background-color: ${THEME.PRIMARY}; // rgb(31, 35, 38);
  color: white;
  border-radius: 1rem;
`;
