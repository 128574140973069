import { useState } from 'react';
import { Typography, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { CollapsiblePanelHeaderWrapper } from './style';
import ICollapsiblePanelProps from './types';

const CollapsiblePanel = (props: ICollapsiblePanelProps) => {
  const [isPanelCollapsed, setCollapsedState] = useState(!props.open);

  return (
    <>
      <CollapsiblePanelHeaderWrapper onClick={() => setCollapsedState(!isPanelCollapsed)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          {props.icon}
          <Typography fontWeight="bold">{props.title}</Typography>
        </div>
        {isPanelCollapsed ? <ExpandMore fontSize="large" /> : <ExpandLess fontSize="large" />}
      </CollapsiblePanelHeaderWrapper>
      <Collapse in={!isPanelCollapsed} unmountOnExit>
        {props.children}
      </Collapse>
    </>
  );
};

export default CollapsiblePanel;
