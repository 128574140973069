import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
`;

export const SvgContainer = styled.div`
  position: relative;
  width: 50%;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
`;

export const SpaceSvgWrapper = styled.div`
  position: absolute;
  width: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
`;

export const CorgySvgWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 50px;
  transform: rotate(-10deg);
  animation: bob 30s ease-in-out infinite;
  will-change: transform;
  @keyframes bob {
    0% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(-10deg);
    }
  }
`;
