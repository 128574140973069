import { useState } from 'react';
import { Modal, Typography, Stack, Zoom, TextField } from '@mui/material';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, SecondaryButton } from 'style/common';
import { THEME } from 'utils/constants';
import { hexToRGBA } from 'utils/commonUtils';

import { Container } from './style';
import IConfirmationModal from './types';

const ConfirmationModal = (props: IConfirmationModal) => {
  const { t } = useTranslation();

  const [textFieldValue, setTextFieldValue] = useState('');

  return (
    <Modal
      open={props.isVisible}
      onClose={() => {
        setTextFieldValue('');
        props.onCancelCbk && props.onCancelCbk();
      }}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(5px)',
      }}
    >
      <Zoom timeout={100} unmountOnExit in={props.isVisible}>
        <Container>
          <Stack justifyContent="end" p={4} spacing={2} sx={{ borderRadius: '1rem' }}>
            <Typography variant="h6" fontWeight="bold">
              {props.title}
            </Typography>
            {props.message && <Typography variant="body1">{props.message}</Typography>}
            {props.confirmByTextFieldValue && (
              <TextField
                placeholder={t('type_to_confirm', { confirmationText: props.confirmByTextFieldValue })}
                onChange={(e) => setTextFieldValue(e.target.value)}
                sx={{
                  input: {
                    color: THEME.TEXT,
                    background: THEME.SECONDARY, // "#272C38",
                    borderRadius: '0.5rem',
                  },
                  label: {
                    color: THEME.TEXT,
                  },
                }}
              ></TextField>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent={props.onCancelCbk == null ? 'center' : 'end'}
            p={2}
            spacing={2}
            sx={{
              bgcolor: hexToRGBA(THEME.PRIMARY, 0.6),
              borderBottomLeftRadius: '1rem',
              borderBottomRightRadius: '1rem',
            }}
          >
            <PrimaryButton
              onClick={props.onConfirmCbk}
              sx={{ fontSize: '14px' }}
              disabled={
                (props.confirmByTextFieldValue && textFieldValue !== props.confirmByTextFieldValue) ||
                props.isConfirmDisabled
              }
            >
              {props.confirmText ?? t('yes')}
            </PrimaryButton>
            {props.cancelText && (
              <SecondaryButton onClick={() => props.onCancelCbk && props.onCancelCbk()} sx={{ fontSize: '14px' }}>
                {props.cancelText}
              </SecondaryButton>
            )}
          </Stack>
        </Container>
      </Zoom>
    </Modal>
  );
};

export default ConfirmationModal;
