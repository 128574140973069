import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, CardMedia, Typography, Grow, Stack, IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { green } from '@mui/material/colors';

import { GlobalContext } from 'App';
import { ELLIPSIS, ROOM_URL_PATH, THEME } from 'utils/constants';
import { getDefaultBannerImgUrl, truncateText } from 'utils/commonUtils';

import { StyledRoomPaper } from './style';
import IRoomTile from './types';

const PAPER_ELEVATION = 3;

const RoomTile = (props: IRoomTile) => {
  const history = useHistory();
  const [isMoreMenuVisible, toggleMoreMenu] = useState(false);
  const [isHoveringOnMoreMenuBtn, setIsHoveringOnMoreMenuBtn] = useState(false);

  const [moreMenuAnchor, setMoreMenuAnchor] = useState<HTMLElement>(null);
  const isMoreMenuOpen = Boolean(moreMenuAnchor);

  //   useEffect(() => toggleMoreMenu(isMoreMenuOpen), [isMoreMenuOpen]);

  console.log(props.title);
  const handleClose = () => {
    toggleMoreMenu(false);
    setIsHoveringOnMoreMenuBtn(false);
    setMoreMenuAnchor(null);
  };

  return (
    <Grow key={`grow-${props.vanity_url}`} in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 }}>
      <Grid key={'room' + props.vanity_url} item xs={12} sm={6} md={4} xl={3}>
        <StyledRoomPaper
          elevation={PAPER_ELEVATION}
          style={{
            borderRadius: '.5rem',
            position: 'relative',
          }}
          onMouseOver={() => toggleMoreMenu(true)}
          onMouseLeave={handleClose}
          {...(isMoreMenuVisible && isHoveringOnMoreMenuBtn
            ? {}
            : { onClick: () => history.push(`${ROOM_URL_PATH}${props.vanity_url}`) })}
        >
          <>
            <CardMedia
              style={{ borderTopLeftRadius: '.5rem', borderTopRightRadius: '.5rem' }}
              component="img"
              height="200"
              image={props.banner || getDefaultBannerImgUrl()}
              alt="The banner of the room"
            />
            {isMoreMenuVisible && (
              <div>
                <IconButton
                  sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => setMoreMenuAnchor(event.currentTarget)}
                  onMouseOver={() => setIsHoveringOnMoreMenuBtn(true)}
                  onMouseLeave={() => setIsHoveringOnMoreMenuBtn(false)}
                >
                  <MoreVert sx={{ color: THEME.TEXT }} />
                </IconButton>
                <Menu
                  id="room-settings-menu"
                  anchorEl={moreMenuAnchor}
                  open={isMoreMenuOpen}
                  onClose={handleClose}
                  onMouseEnter={() => setIsHoveringOnMoreMenuBtn(true)}
                  onMouseLeave={() => setIsHoveringOnMoreMenuBtn(false)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  PaperProps={{
                    elevation: 4,
                    sx: {
                      backgroundColor: THEME.SECONDARY,
                    },
                  }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <MenuItem onClick={handleClose}>
                    <Typography variant="body1">Edit{ELLIPSIS}</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose;
                      props.setRoomToDelete(props.vanity_url);
                    }}
                  >
                    <Typography variant="body1" color="error" fontWeight="bold">
                      Delete
                    </Typography>
                  </MenuItem>
                </Menu>
              </div>
            )}
            <Stack flexDirection="column" padding={2}>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Tooltip title={truncateText(props.title, 32).length === props.title.length ? '' : props.title}>
                  <Typography gutterBottom variant="h6">
                    {truncateText(props.title, 32)}
                  </Typography>
                </Tooltip>
                <Typography gutterBottom variant="h6">
                  {props.approximate_user_count}\{props.capacity}
                </Typography>
              </Stack>
              <Tooltip
                title={
                  truncateText(props.description, 100)?.length === props.description?.length ? '' : props.description
                }
              >
                <Typography variant="body2" color="rgba(255, 255, 255, 0.6)">
                  {props.description == null || props.description === ''
                    ? 'No description'
                    : truncateText(props.description, 100)}
                </Typography>
              </Tooltip>
            </Stack>
          </>
        </StyledRoomPaper>
      </Grid>
    </Grow>
  );
};

export default RoomTile;
