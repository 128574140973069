import { ROOM_URL_PATH } from 'utils/constants';

interface ICreateRoomResponse {
  room_id: string;
}

export const createRoom = async (): Promise<string> => {
  const response = await fetch('/api/rooms/create', {
    method: 'POST',
  });
  const data: ICreateRoomResponse = await response.json();
  return response.status === 200 ? `${ROOM_URL_PATH}${data.room_id}` : '/';
};

export const deleteRoom = async (id: string): Promise<boolean> => {
  const response = await fetch(`/api/rooms/${id}`, {
    method: 'DELETE',
  });
  return response.status === 200;
};

export interface IRoomPanelData {
  vanity_url: string;
  banner?: string;
  title: string;
  description?: string;
  tags: string[];
  approximate_user_count: number;
  capacity: number;
}

export const getRoomsOfUser = async (): Promise<IRoomPanelData[]> => {
  const response = await fetch('/api/rooms');
  const data: IRoomPanelData[] = await response.json();
  return response.status === 200 ? data : null;
};

export const inviteToRoom = async (userId: string): Promise<boolean> => {
  // TODO Send invite request to user and return whether it was successful
  await new Promise((resolve) => setTimeout(resolve, Math.round(Math.random() * 2000))); // Wait for 2 seconds
  return false;
};
