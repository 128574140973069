import styled from 'styled-components';

import { THEME } from 'utils/constants';

export const Container = styled.div`
  width: 1000px;
  height: 800px;
  max-height: 800px;
  background-color: ${THEME.PRIMARY};
  border-radius: 1rem;
`;
