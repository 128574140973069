import styled from 'styled-components';
import { styled as styledMUI } from '@mui/system';

import { InputLabel, Select } from '@mui/material';

import { THEME } from 'utils/constants';

export const StyledInputLabel = styledMUI(InputLabel)({
  'color': THEME.IMPORTANT_TEXT,
  'pointerEvents': 'none',
  'zIndex': 1,
  '&.MuiInputLabel-shrink': {
    color: THEME.IMPORTANT_TEXT,
  },
});

export const StyledSelect = styledMUI(Select)({
  'color': THEME.TEXT,
  'background': THEME.SECONDARY,
  '&:focus': { borderRadius: 4, boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)' },
  '& .MuiSelect-icon': {
    color: THEME.TEXT,
  },
});

export const DOBContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RegisterFooter = styled.div`
  margin-top: 1rem;
`;

export const UnauthorizedRegisterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
