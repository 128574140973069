import styled from 'styled-components';

import { Button } from '@mui/material';

import { THEME } from 'utils/constants';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1606;
  background: ${THEME.PRIMARY}; //rgb(31, 35, 38);
  display: flex;
  flex-direction: column;
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15rem;
  max-height: 80px;
  padding: 0 4rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

export const StyledButton = styled(Button)`
  width: 100%;
  min-height: 24rem;
  background: ${THEME.SECONDARY} !important; //#272c38 !important;
  border-radius: 1rem !important;
  &:hover {
    background: #fff !important;
    transition: all 0.4s;
  }
`;

export const TwitchButton = styled(StyledButton)`
  &:hover {
    background: #9147fe !important;
  }
`;
export const SpotifyButton = styled(StyledButton)`
  &:hover {
    background: #1ed760 !important;
  }
`;
export const SoundcloudButton = styled(StyledButton)`
  &:hover {
    background: #f26e23 !important;
  }
`;
export const ScreenshareButton = styled(StyledButton)`
  &:hover {
    background: rgb(25 118 210) !important;
  }
`;

export const CustomSVGWrapper = styled.div`
  display: flex;
  align-items: center;
`;
