import styled from 'styled-components';

import { THEME } from 'utils/constants';

export const FooterWrapper = styled.div`
  color: ${THEME.IMPORTANT_TEXT};
  background-color: ${THEME.PRIMARY}; //rgb(34, 34, 34) !important;
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;
