import styled from 'styled-components';

import { THEME } from 'utils/constants';

export const TermsWrapper = styled.div`
  background-color: ${THEME.PRIMARY};
`;

export const Container = styled.div`
  color: #dfdfdf;
  margin: 5rem;
`;

export const Link = styled.a`
  color: rgb(55 148 203);
`;
