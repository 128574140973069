import { useState, useRef, createContext, useMemo } from 'react';

import useSocket from './SocketEvents';

import { Box } from '@mui/material';

import { Socket } from 'socket.io-client';
import { LoadingContainer } from 'style/common';
import Chat from 'views/Room/Chatbox';
import VideoPlayer from './VideoPlayer';

import { ICachedRoom, IServerEvents, IClientEvents } from './socketEventTypes';
import { RoomContainer } from './style';

interface IRoomContext {
  socketRef: React.MutableRefObject<Socket<IClientEvents, IServerEvents>>;
  roomRef: React.MutableRefObject<ICachedRoom>;
  currentUserSocketId: string;
  setCurrentUserSocketId: (str: string) => void;
  isBrowseMediaPopupVisible: boolean;
  setBrowseMediaPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isOverlayMenuVisible: boolean;
  setOverlayMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isMuted: boolean;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  isHost: boolean;
}

export const RoomContext = createContext<IRoomContext>(null);

const Room = () => {
  const [loaded, setLoaded] = useState(false);

  const socketRef = useRef<Socket<IClientEvents, IServerEvents>>();
  const roomRef = useRef<ICachedRoom>();

  const [currentUserSocketId, setCurrentUserSocketId] = useState('');
  const [isMuted, setMuted] = useState(true);

  // ui components
  const [isBrowseMediaPopupVisible, setBrowseMediaPopupVisible] = useState(false);
  const [isOverlayMenuVisible, setOverlayMenuVisible] = useState(false);

  // create socket and join room
  useSocket(socketRef, setLoaded);

  const isHost = useMemo(() => currentUserSocketId === roomRef.current?.hostSocketId, [currentUserSocketId]);

  return (
    <RoomContainer>
      {loaded ? (
        <RoomContext.Provider
          value={{
            socketRef,
            roomRef,
            currentUserSocketId,
            setCurrentUserSocketId,
            isBrowseMediaPopupVisible,
            setBrowseMediaPopupVisible,
            isOverlayMenuVisible,
            setOverlayMenuVisible,
            isMuted,
            setMuted,
            isHost,
          }}
        >
          <Box display="flex" flexWrap="wrap" height="100%" sx={{ backdropFilter: 'blur(1rem)' }}>
            <VideoPlayer />
            <Chat />
          </Box>
        </RoomContext.Provider>
      ) : (
        <LoadingContainer />
      )}
    </RoomContainer>
  );
};

export default Room;
