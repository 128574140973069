import styled from 'styled-components';

export const NotificationsPanelWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 40vw;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
`;
