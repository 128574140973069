import { styled as styledMUI } from '@mui/system';

import { Paper } from '@mui/material';

import { THEME } from 'utils/constants';

export const StyledRoomPaper = styledMUI(Paper)({
  'backgroundColor': THEME.SECONDARY,
  'transition': 'transform 50ms',
  'userSelect': 'none',
  'cursor': 'pointer',
  'minHeight': '313px',
  '&:hover': {
    transform: 'scale(0.99)',
    backgroundColor: THEME.TERTIARY,
  },
});
