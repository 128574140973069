import { Stack } from '@mui/material';
import { Twitter, Instagram, Facebook, YouTube } from '@mui/icons-material';

const Socials = () => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Twitter sx={{ cursor: 'pointer', mr: 2 }} />
      <Instagram sx={{ cursor: 'pointer', mr: 2 }} />
      <Facebook sx={{ cursor: 'pointer', mr: 2 }} />
      <YouTube sx={{ cursor: 'pointer', mr: 2 }} />
    </Stack>
  );
};

export default Socials;
