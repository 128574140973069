import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  IconButton,
  Slide,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Tooltip,
} from '@mui/material';
import { Close, Search, PersonAdd } from '@mui/icons-material';

import { THEME } from 'utils/constants';
import { RoomContext } from 'views/Room/Room';
import { ICachedUser } from 'views/Room/socketEventTypes';
import CROWN_ICON_PNG from 'assets/icons/crown.png';
import GROUP_ICON_PNG from 'assets/icons/group.png';
import CollapsiblePanel from 'components/common/CollapsiblePanel';

import InviteFriendsModal from '../InviteFriendsModal';
import IParticipantList from './types';
import { StyledImg } from './style';

const Participant = (props: ICachedUser) => {
  return (
    <Stack margin={2} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            alt="Participant's Avatar"
            src={'/img/default_avatar.jpg'}
            sx={{
              width: '25px',
              height: '25px',
              backgroundColor: props?.avatar == null ? props.accent_color : 'none',
            }}
          />
          <Typography>{props.username}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ParticipantList = (props: IParticipantList) => {
  const { t } = useTranslation();
  const { roomRef, socketRef, currentUserSocketId } = useContext(RoomContext);
  const [numOfParticipants, setNumOfParticipants] = useState(1);
  const [userList, setUserList] = useState<Record<string, ICachedUser>>(null);
  const [isInviteModalVisible, toggleInviteModalView] = useState(false);

  const establishedSocketEventsRef = useRef(false);

  useEffect(() => {
    setUserList(roomRef.current?.users);
    setNumOfParticipants(roomRef.current?.userCount);
  }, [currentUserSocketId]);

  useEffect(() => {
    const socket = socketRef.current;

    if (!establishedSocketEventsRef.current) {
      socket.on('userJoined', async (data) => {
        console.log('userJoined', data);
        roomRef.current.users[data.socketId] = data.user;
        roomRef.current.userCount++;
        setNumOfParticipants(roomRef.current.userCount);
        setUserList(roomRef.current.users);
      });

      socket.on('guestUserJoinedChat', async (data) => {
        if (roomRef.current) {
          roomRef.current.users[data.socketId].username = data.updatedChatUsername;
        }
      });

      socket.on('userLeft', async (data) => {
        delete roomRef.current.users[data.socketId];
        roomRef.current.userCount--;
        if (data.newHostSocketId?.length > 0) {
          roomRef.current.hostSocketId = data.newHostSocketId;
        }
        setNumOfParticipants(roomRef.current.userCount);
        setUserList(roomRef.current.users);
      });

      establishedSocketEventsRef.current = true;
    }

    // clean-up event listeners
    return () => {
      socket.off('userJoined');
      socket.off('userLeft');
      establishedSocketEventsRef.current = false;
    };
  }, []);

  return (
    <Slide direction="up" in={props.isVisible} mountOnEnter unmountOnExit>
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          background: THEME.PRIMARY,
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between" p={2}>
            <Typography fontWeight="bold">
              {t('participants_view.title')} - {numOfParticipants}
            </Typography>
            <div>
              <Tooltip title={t('invite_friends')}>
                <IconButton onClick={() => toggleInviteModalView(true)} size="medium">
                  <PersonAdd style={{ color: THEME.TEXT }} />
                </IconButton>
              </Tooltip>
              <InviteFriendsModal isVisible={isInviteModalVisible} toggle={toggleInviteModalView} />
              <Tooltip title={t('close')}>
                <IconButton onClick={() => props.toggle(false)} size="medium">
                  <Close style={{ color: THEME.TEXT }} />
                </IconButton>
              </Tooltip>
            </div>
          </Stack>
          <div style={{ overflow: 'auto', flexGrow: 1, padding: '1rem' }}>
            <Divider sx={{ mb: 2 }} />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" variant="outlined">
                    <Search sx={{ color: THEME.IMPORTANT_TEXT }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                'input': {
                  color: THEME.IMPORTANT_TEXT,
                  background: '#272C38',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#272C38',
                },
              }}
              placeholder="Filter"
              autoComplete="off"
              fullWidth
            />
            <Divider sx={{ pt: 2 }} />
            <div>
              <CollapsiblePanel
                title={t('participants_view.hosts')}
                icon={<StyledImg alt="Host Icon" src={CROWN_ICON_PNG} />}
                open
              >
                <>
                  {userList &&
                    Object.entries(userList).map(
                      ([key, value]) =>
                        key === roomRef.current?.hostSocketId && (
                          <Participant
                            key={key}
                            avatar={value.avatar}
                            accent_color={value.accent_color}
                            username={
                              value.username === roomRef.current?.users[currentUserSocketId].username
                                ? `${value.username} (You)`
                                : value.username
                            }
                          />
                        ),
                    )}
                </>
              </CollapsiblePanel>
            </div>
            <Divider />
            <div>
              <CollapsiblePanel
                title={t('participants_view.viewers')}
                icon={<StyledImg alt="Viewer Icon" src={GROUP_ICON_PNG} />}
              >
                <>
                  {userList &&
                    Object.entries(userList).map(
                      ([key, value]) =>
                        key !== roomRef.current?.hostSocketId && (
                          <Participant
                            key={key}
                            avatar={value.avatar}
                            accent_color={value.accent_color}
                            username={
                              value.username === roomRef.current?.users[currentUserSocketId].username
                                ? `${value.username} (You)`
                                : value.username
                            }
                          />
                        ),
                    )}
                </>
              </CollapsiblePanel>
            </div>
            <Divider />
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default ParticipantList;
