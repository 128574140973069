import { useState, useEffect } from 'react';

import { TextField, Button, LinearProgress, Box, Typography } from '@mui/material';

import { Container } from './style';

import { useTranslation } from 'react-i18next';

const WebTorrent = (props: any) => {
  const { playerRef, socketRef, roomId, setTracks, onURLChange, onChangeVideo } = props;

  const [torrentFile, setTorrentFile] = useState();
  const [progress, setProgress] = useState(0);

  let progressInterval: NodeJS.Timeout;
  const { t } = useTranslation();

  useEffect(() => {
    getProgress();

    socketRef.current.on('onUpdateTracks', (data: any) => {
      let tracks = '';
      for (let i = 0; i < data.length; i++)
        tracks += '<track src="' + data[i].src + '" kind="subtitles" label="' + data[i].srcLang + '" />';
      playerRef.current.getInternalPlayer().innerHTML = tracks;
      setTracks(data);
    });

    return () => {
      clearInterval(progressInterval);
    };
  }, [socketRef]);

  const getProgress = () => {
    if (progressInterval != null) return;
    progressInterval = setInterval(async () => {
      fetch('/rooms/' + roomId + '/progress', {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          const { prog } = data;
          setProgress(prog * 100);
          if (prog === 0) clearInterval(progressInterval);
        });
    }, 1000);
  };

  // const downloadTorrent = (torrentId) => {
  //   wtclient.current.add(torrentId, (torrent) => {
  //     torrentProgessEvent = setInterval(() => {
  //       setTorrentState({
  //         infoHash: torrent.infoHash,
  //         magnetURI: torrent.magnetURI,
  //         name: torrent.name,
  //         progress: (torrent.progress * 100).toFixed(2) + "%",
  //         downloadSpeed: (torrent.downloadSpeed / 1024 ** 2).toFixed(2) + "MB/s",
  //         uploadSpeed: (torrent.uploadSpeed / 1024 ** 2).toFixed(2) + "MB/s"
  //       });
  //     }, 1000);

  //     let file = torrent.files.find((file) => {
  //       return file.name.endsWith(".mp4");
  //     });

  //     console.log(file);

  //     file.getBlobURL((err, url) => {
  //       if (err) return console.log(err.message);
  //       console.log("File done.");
  //       setSource(url);
  //       if (currentUserSocketId === room.current.host) socket.current.emit("onChangeVideo", url);
  //     });
  //   });
  // };

  const onUploadFile = async (e: any) => {
    const formData = new FormData();
    formData.append('torrentFile', e.target.files[0]);

    fetch('/rooms/' + roomId, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        socketRef.current.emit(
          'onChangeVideo',
          roomId + '/' + data.fileName, //+ data.fileName.slice(data.fileName.length - 3, data.fileName.length)
        );
        if (data.subtitles) socketRef.current.emit('onUpdateTracks', data.subtitles);
      });

    getProgress();
  };

  const LinearProgressWithLabel = (props: {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: number;
  }) => {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Container>
      <TextField
        label="Paste magnet URI or infohash"
        variant="outlined"
        style={{ marginBottom: '0.5rem', width: '100%' }}
        onChange={onURLChange}
        onKeyDown={onChangeVideo}
      />
      {t('or')}
      <Button variant="contained" component="label" style={{ marginLeft: '0.5rem', textAlign: 'center' }}>
        {t('upload_file')}
        <input ref={torrentFile} type="file" name="torrentFile" hidden onChange={onUploadFile} />
      </Button>
      {progress > 0 && <LinearProgressWithLabel value={progress} />}
    </Container>
  );
};

export default WebTorrent;
