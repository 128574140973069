import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ListItemIcon, Divider, Typography, Menu, MenuItem } from '@mui/material';
import { LogoutOutlined, Settings, ContactSupport } from '@mui/icons-material';
import { red } from '@mui/material/colors';

import { logoutUser } from 'api/auth';
import { THEME } from 'utils/constants';
import { GlobalContext } from 'App';

import IProfileMenu from './types';

import { useTranslation } from 'react-i18next';

const ProfileMenu = (props: IProfileMenu) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAnchorEl } = props;
  const { setUserProfileData } = useContext(GlobalContext);

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.anchorEl != null}
      onClose={() => setAnchorEl(null)}
      PaperProps={{
        elevation: 4,
        sx: {
          'overflow': 'visible',
          'backgroundColor': THEME.SECONDARY,
          'mt': 2,
          'minWidth': '200px',
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 5,
            right: 15,
            width: 20,
            height: 20,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            backgroundColor: THEME.SECONDARY,
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        onClick={(e) => {
          history.push('/settings/profile');
          setAnchorEl(null);
        }}
      >
        <ListItemIcon style={{ color: THEME.TEXT }}>
          <Settings fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1">{t('settings.title')}</Typography>
      </MenuItem>
      <Divider style={{ backgroundColor: '#383838' }} />
      <MenuItem onClick={(e) => history.push('/support')}>
        <ListItemIcon style={{ color: THEME.TEXT }}>
          <ContactSupport fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1">{t('support')}</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          // ? shoud we wait to see if server successfully logged out to clear the local session?
          logoutUser();
          setUserProfileData(null);
        }}
      >
        <ListItemIcon style={{ color: THEME.TEXT }}>
          <LogoutOutlined fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1" color={red[400]} fontWeight="600">
          {t('logout')}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
