type EmojiGroup =
  | 'Smileys'
  | 'People'
  | 'Nature'
  | 'Food'
  | 'Activities'
  | 'Travel'
  | 'Objects'
  | 'Symbols'
  | 'Flags'
  | 'Custom';

interface IEmoji {
  emoji: string; // unicode
  tags: string[];
  group: EmojiGroup;
}

export const LIST_OF_EMOJIS: IEmoji[] = [
  { emoji: '😀', tags: ['happy', 'joyful', 'smile', 'grinning', 'positive'], group: 'Smileys' },
  { emoji: '😃', tags: ['happy', 'cheerful', 'smile', 'joy', 'excited'], group: 'Smileys' },
  { emoji: '😄', tags: ['happy', 'laughing', 'smile', 'joy', 'excitement'], group: 'Smileys' },
  { emoji: '😁', tags: ['grin', 'smiling', 'joyful', 'happy', 'cheerful'], group: 'Smileys' },
  { emoji: '😆', tags: ['laughing', 'gleeful', 'joy', 'happy', 'excitement'], group: 'Smileys' },
  { emoji: '😅', tags: ['happy', 'smile', 'relief', 'sweating', 'joy'], group: 'Smileys' },
  { emoji: '🤣', tags: ['laughter', 'rolling on the floor laughing', 'hilarious', 'joy'], group: 'Smileys' },
  { emoji: '😂', tags: ['laughter', 'tears of joy', 'funny', 'haha', 'humor'], group: 'Smileys' },
  { emoji: '🙂', tags: ['neutral', 'neutral face', 'content', 'okay', 'so-so'], group: 'Smileys' },
  { emoji: '😉', tags: ['winking', 'flirt', 'mischievous', 'playful', 'sly'], group: 'Smileys' },
  { emoji: '😊', tags: ['blushing', 'smile', 'content', 'happy', 'joy'], group: 'Smileys' },
  { emoji: '😇', tags: ['angelic', 'innocent', 'saintly', 'virtuous', 'benevolent'], group: 'Smileys' },
  { emoji: '🥰', tags: ['loving', 'heart-eyes', 'adoration', 'affectionate', 'romantic'], group: 'Smileys' },
  { emoji: '😍', tags: ['heart-eyes', 'in love', 'infatuated', 'adoration', 'lovestruck'], group: 'Smileys' },
  { emoji: '🤩', tags: ['star-struck', 'excitement', 'awe', 'impressed', 'celebrity'], group: 'Smileys' },
  { emoji: '😘', tags: ['kiss', 'blowing a kiss', 'affection', 'love', 'romantic'], group: 'Smileys' },
  { emoji: '😗', tags: ['kiss', 'whistle', 'lips', 'pucker up', 'smooch'], group: 'Smileys' },
  { emoji: '😚', tags: ['kiss', 'affectionate', 'tender', 'sweet', 'loving'], group: 'Smileys' },
  { emoji: '😙', tags: ['kiss', 'smile', 'affection', 'pucker up', 'kissing'], group: 'Smileys' },
  { emoji: '😏', tags: ['smirk', 'sly', 'knowing', 'suggestive', 'flirtatious'], group: 'Smileys' },
  { emoji: '😋', tags: ['delicious', 'yum', 'tasty', 'food', 'savoring'], group: 'Smileys' },
  { emoji: '😛', tags: ['tongue', 'playful', 'joking', 'teasing', 'fun'], group: 'Smileys' },
  { emoji: '😜', tags: ['wink', 'playful', 'silly', 'fun', 'cheeky'], group: 'Smileys' },
  { emoji: '🤪', tags: ['crazy', 'wild', 'zany', 'goofy', 'insane'], group: 'Smileys' },
  { emoji: '😝', tags: ['tongue', 'playful', 'joking', 'teasing', 'funny'], group: 'Smileys' },
  { emoji: '🤗', tags: ['hug', 'embrace', 'warm', 'friendly', 'affectionate'], group: 'Smileys' },
  { emoji: '🤭', tags: ['hand over mouth', 'giggling', 'blushing', 'shy', 'gossip'], group: 'Smileys' },
  { emoji: '🤫', tags: ['shushing', 'quiet', 'secret', 'hush', 'keep it quiet'], group: 'Smileys' },
  { emoji: '🤔', tags: ['thinking', 'pondering', 'contemplating', 'curious', 'inquiry'], group: 'Smileys' },
  { emoji: '🤤', tags: ['drooling', 'yum', 'mouth-watering', 'delicious', 'appetizing'], group: 'Smileys' },
  { emoji: '🤠', tags: ['cowboy', 'wild west', 'yeehaw', 'frontier', 'outlaw'], group: 'Smileys' },
  { emoji: '🥳', tags: ['party', 'celebration', 'excited', 'happy', 'festive'], group: 'Smileys' },
  { emoji: '😎', tags: ['cool', 'sunglasses', 'chill', 'hip', 'stylish'], group: 'Smileys' },
  { emoji: '🤓', tags: ['nerd', 'geek', 'smart', 'intelligent', 'studious'], group: 'Smileys' },
  { emoji: '🧐', tags: ['studying', 'examining', 'curious', 'scrutinizing', 'inquiring'], group: 'Smileys' },
  { emoji: '🙃', tags: ['upside-down', 'silly', 'playful', 'flipped', 'fun'], group: 'Smileys' },
  { emoji: '🤐', tags: ['zipper-mouth', 'quiet', 'secrecy', 'hush', 'confidential'], group: 'Smileys' },
  { emoji: '🤨', tags: ['raised eyebrow', 'doubt', 'skepticism', 'questioning', 'suspicion'], group: 'Smileys' },
  { emoji: '😐', tags: ['neutral', 'indifferent', 'meh', 'unimpressed', 'bored'], group: 'Smileys' },
  { emoji: '😑', tags: ['expressionless', 'blank face', 'stoic', 'deadpan', 'apathetic'], group: 'Smileys' },
  { emoji: '😶', tags: ['speechless', 'mute', 'silenced', 'quiet', 'wordless'], group: 'Smileys' },
  { emoji: '😒', tags: ['unhappy', 'discontent', 'annoyed', 'irritated', 'unimpressed'], group: 'Smileys' },
  { emoji: '🙄', tags: ['eye roll', 'skeptical', 'annoyed', 'exasperated', 'impatient'], group: 'Smileys' },
  { emoji: '😬', tags: ['grimace', 'awkward', 'nervous', 'embarrassed', 'anxious', 'tired'], group: 'Smileys' },
  { emoji: '😮‍💨', tags: ['shocked', 'surprised', 'speechless', 'gasp', 'astonished'], group: 'Smileys' },
  { emoji: '🤥', tags: ['lying', 'fibbing', 'dishonest', 'untruthful', 'deceitful'], group: 'Smileys' },
  { emoji: '😌', tags: ['relieved', 'calm', 'peaceful', 'satisfied', 'content'], group: 'Smileys' },
  { emoji: '😔', tags: ['sad', 'disappointed', 'downhearted', 'regretful', 'dejected'], group: 'Smileys' },
  { emoji: '😪', tags: ['sleepy', 'tired', 'exhausted', 'drowsy', 'fatigued'], group: 'Smileys' },
  { emoji: '😴', tags: ['sleeping', 'zzz', 'nap', 'rest', 'snoring'], group: 'Smileys' },
  { emoji: '😷', tags: ['medical mask', 'sick', 'ill', 'health', 'cough', 'covid'], group: 'Smileys' },
  { emoji: '🤒', tags: ['feverish', 'unwell', 'sick', 'ill', 'temperature'], group: 'Smileys' },
  { emoji: '🤕', tags: ['injured', 'hurt', 'head bandage', 'bruised', 'ouch'], group: 'Smileys' },
  { emoji: '🤢', tags: ['nauseated', 'sick', 'vomit', 'disgusted', 'unwell'], group: 'Smileys' },
  { emoji: '🤮', tags: ['vomiting', 'throwing up', 'disgust', 'nausea', 'sick'], group: 'Smileys' },
  { emoji: '🤧', tags: ['sneezing', 'sick', 'achoo', 'allergies', 'cold'], group: 'Smileys' },
  { emoji: '🥵', tags: ['hot', 'overheated', 'sweating', 'burning', 'fever', 'sexy'], group: 'Smileys' },
  { emoji: '🥶', tags: ['cold', 'freezing', 'shivering', 'frozen', 'chill'], group: 'Smileys' },
  { emoji: '🥴', tags: ['woozy', 'unsteady', 'intoxicated', 'drunk', 'nauseous'], group: 'Smileys' },
  { emoji: '😵', tags: ['dizzy', 'unconscious', 'fainted', 'confused', 'shocked'], group: 'Smileys' },
  { emoji: '😵‍💫', tags: ['mind-blown', 'overwhelmed', 'shock', 'astonishment', 'unbelievable'], group: 'Smileys' },
  { emoji: '🤯', tags: ['mind-blown', 'exploding head', 'shocked', 'amazed', 'unbelievable'], group: 'Smileys' },
  { emoji: '🥱', tags: ['yawning', 'tired', 'bored', 'sleepy', 'fatigued'], group: 'Smileys' },
  { emoji: '😕', tags: ['confused', 'uncertain', 'puzzled', 'bewildered', 'doubtful'], group: 'Smileys' },
  { emoji: '😟', tags: ['worried', 'concerned', 'anxious', 'nervous', 'upset'], group: 'Smileys' },
  { emoji: '🙁', tags: ['frowning', 'sad', 'unhappy', 'dejected', 'disappointed'], group: 'Smileys' },
  { emoji: '😮', tags: ['open mouth', 'surprised', 'astonished', 'shocked', 'wow'], group: 'Smileys' },
  { emoji: '😯', tags: ['open mouth', 'surprised', 'astonished', 'shocked', 'wow'], group: 'Smileys' },
  { emoji: '😲', tags: ['astonished', 'amazed', 'open mouth', 'shocked', 'wow'], group: 'Smileys' },
  { emoji: '😳', tags: ['flushed', 'embarrassed', 'shocked', 'blushing', 'surprised'], group: 'Smileys' },
  { emoji: '🥺', tags: ['pleading', 'puppy eyes', 'begging', 'sorrow', 'desire'], group: 'Smileys' },
  { emoji: '😦', tags: ['frowning', 'worried', 'anxious', 'nervous', 'concerned'], group: 'Smileys' },
  { emoji: '😧', tags: ['anguished', 'hurting', 'sad', 'pain', 'suffering'], group: 'Smileys' },
  { emoji: '😨', tags: ['fear', 'afraid', 'scared', 'terrified', 'horrified'], group: 'Smileys' },
  { emoji: '😰', tags: ['anxious', 'nervous', 'sweating', 'worried', 'fearful'], group: 'Smileys' },
  { emoji: '😥', tags: ['sad', 'crying', 'teary-eyed', 'upset', 'dejected'], group: 'Smileys' },
  { emoji: '😢', tags: ['crying', 'tearful', 'sad', 'upset', 'heartbroken'], group: 'Smileys' },
  { emoji: '😭', tags: ['crying', 'sobbing', 'heartbroken', 'sad', 'mourning'], group: 'Smileys' },
  { emoji: '😱', tags: ['screaming', 'shocked', 'fear', 'horror', 'terrified'], group: 'Smileys' },
  { emoji: '😖', tags: ['confounded', 'upset', 'frustrated', 'distressed', 'annoyed'], group: 'Smileys' },
  { emoji: '😣', tags: ['persevering', 'struggling', 'frustrated', 'exasperated', 'determined'], group: 'Smileys' },
  { emoji: '😞', tags: ['disappointed', 'downcast', 'regret', 'sad', 'unhappy'], group: 'Smileys' },
  { emoji: '😓', tags: ['sweat', 'worried', 'nervous', 'anxious', 'troubled'], group: 'Smileys' },
  { emoji: '😩', tags: ['weary', 'exhausted', 'tired', 'frustrated', 'stressed'], group: 'Smileys' },
  { emoji: '😫', tags: ['tired', 'exasperated', 'exhausted', 'suffering', 'despair'], group: 'Smileys' },
  { emoji: '😤', tags: ['steam from nose', 'angry', 'frustrated', 'mad', 'annoyed'], group: 'Smileys' },
  { emoji: '😡', tags: ['angry', 'irate', 'mad', 'upset', 'frustrated'], group: 'Smileys' },
  { emoji: '😠', tags: ['angry', 'grumpy', 'furious', 'upset', 'irritated'], group: 'Smileys' },
  { emoji: '🤬', tags: ['cursing', 'swearing', 'angry', 'mad', 'outraged'], group: 'Smileys' },
  { emoji: '👿', tags: ['devilish', 'evil', 'angry', 'demon', 'satanic'], group: 'Smileys' },
  { emoji: '😈', tags: ['smiling devil', 'mischievous', 'devilish', 'sly', 'wicked'], group: 'Smileys' },
  { emoji: '💀', tags: ['skull', 'dead', 'death', 'grim', 'macabre'], group: 'Smileys' },
  { emoji: '☠️', tags: ['skull and crossbones', 'danger', 'poison', 'deadly', 'lethal'], group: 'Smileys' },
  { emoji: '💩', tags: ['poop', 'funny', 'turd', 'hilarious', 'crude'], group: 'Smileys' },
  { emoji: '🤡', tags: ['clown', 'silly', 'funny', 'joker', 'circus'], group: 'Smileys' },
  { emoji: '👹', tags: ['ogre', 'demon', 'monster', 'evil', 'scary'], group: 'Smileys' },
  { emoji: '👺', tags: ['goblin', 'evil', 'monster', 'scary', 'japanese folklore'], group: 'Smileys' },
  { emoji: '👻', tags: ['ghost', 'spirit', 'haunted', 'spooky', 'supernatural'], group: 'Smileys' },
  { emoji: '👽', tags: ['alien', 'extraterrestrial', 'UFO', 'space', 'otherworldly'], group: 'Smileys' },
  { emoji: '👾', tags: ['alien monster', 'video game', 'space invaders', 'arcade', 'pixel'], group: 'Smileys' },
  { emoji: '🤖', tags: ['robot', 'android', 'ai', 'cybernetic', 'automation'], group: 'Smileys' },
  { emoji: '😺', tags: ['smiling cat', 'happy', 'content', 'pleased', 'grinning'], group: 'Smileys' },
  { emoji: '😸', tags: ['grinning cat with smiling eyes', 'cheerful', 'content', 'happy', 'smile'], group: 'Smileys' },
  { emoji: '😹', tags: ['cat with tears of joy', 'laughing', 'hilarious', 'funny', 'humor'], group: 'Smileys' },
  { emoji: '😻', tags: ['heart-eyes cat', 'adoration', 'in love', 'affectionate', 'adorable'], group: 'Smileys' },
  { emoji: '😼', tags: ['cat with wry smile', 'smirking', 'sly', 'knowing', 'mischievous'], group: 'Smileys' },
  { emoji: '😽', tags: ['kissing cat', 'affectionate', 'love', 'pucker up', 'adorable'], group: 'Smileys' },
  { emoji: '🙀', tags: ['shocked cat', 'surprised', 'amazed', 'frightened', 'astonished'], group: 'Smileys' },
  { emoji: '😿', tags: ['crying cat', 'sad', 'tearful', 'unhappy', 'distressed'], group: 'Smileys' },
  { emoji: '😾', tags: ['pouting cat', 'angry', 'grumpy', 'upset', 'annoyed'], group: 'Smileys' },
  { emoji: '🙈', tags: ['see-no-evil monkey', 'covering eyes', 'embarrassed', 'shy', 'hide'], group: 'Smileys' },
  {
    emoji: '🙉',
    tags: ['hear-no-evil monkey', 'covering ears', 'ignorance', 'avoidance', 'blocked'],
    group: 'Smileys',
  },
  { emoji: '🙊', tags: ['speak-no-evil monkey', 'covering mouth', 'secret', 'silenced', 'mute'], group: 'Smileys' },
  { emoji: '👋', tags: ['waving hand', 'hello', 'greeting', 'goodbye', 'farewell'], group: 'People' },
  { emoji: '🤚', tags: ['raised back of hand', 'stop', 'halt', 'raised hand'], group: 'People' },
  { emoji: '🖐️', tags: ['hand with fingers splayed', 'open hand', 'stop', 'high five'], group: 'People' },
  { emoji: '✋', tags: ['raised hand', 'stop', 'halt', 'gesture', 'high five'], group: 'People' },
  { emoji: '🖖', tags: ['vulcan salute', 'Spock', 'live long and prosper', 'Star Trek'], group: 'People' },
  { emoji: '👌', tags: ['OK hand', 'okay', 'perfect', 'excellent', 'approval'], group: 'People' },
  { emoji: '🤏', tags: ['pinching hand', 'small amount', 'precise', 'pinch'], group: 'People' },
  { emoji: '✌️', tags: ['victory hand', 'peace', 'two fingers', 'v-sign'], group: 'People' },
  { emoji: '🤞', tags: ['crossed fingers', 'hope', 'good luck', 'wishing', 'superstition'], group: 'People' },
  { emoji: '🤟', tags: ['love-you gesture', 'rock and roll', 'ILY', 'affection'], group: 'People' },
  { emoji: '🤘', tags: ['sign of the horns', 'rock on', 'metal', 'rock music'], group: 'People' },
  { emoji: '🤙', tags: ['call me hand', 'hang loose', 'phone', 'communication'], group: 'People' },
  { emoji: '👈', tags: ['backhand index pointing left', 'left', 'direction', 'pointing'], group: 'People' },
  { emoji: '👉', tags: ['backhand index pointing right', 'right', 'direction', 'pointing'], group: 'People' },
  { emoji: '👆', tags: ['backhand index pointing up', 'up', 'direction', 'pointing'], group: 'People' },
  { emoji: '🖕', tags: ['middle finger', 'obscene', 'offensive', 'rude', 'up yours'], group: 'People' },
  { emoji: '👇', tags: ['backhand index pointing down', 'down', 'direction', 'pointing'], group: 'People' },
  { emoji: '☝️', tags: ['index pointing up', 'pointing', 'up', 'direction'], group: 'People' },
  { emoji: '👍', tags: ['thumbs up', 'approval', 'like', 'good', 'positive'], group: 'People' },
  { emoji: '👎', tags: ['thumbs down', 'disapproval', 'dislike', 'bad', 'negative'], group: 'People' },
  { emoji: '✊', tags: ['raised fist', 'power', 'solidarity', 'protest', 'strength'], group: 'People' },
  { emoji: '👊', tags: ['oncoming fist', 'punch', 'fight', 'power', 'collision'], group: 'People' },
  { emoji: '🤛', tags: ['left-facing fist', 'punch', 'fist bump', 'collision'], group: 'People' },
  { emoji: '🤜', tags: ['right-facing fist', 'punch', 'fist bump', 'collision'], group: 'People' },
  { emoji: '👏', tags: ['clapping hands', 'applause', 'bravo', 'congratulations', 'ovation'], group: 'People' },
  { emoji: '🙌', tags: ['raising hands', 'celebration', 'praise', 'excitement', 'worship'], group: 'People' },
  { emoji: '👐', tags: ['open hands', 'gesture', 'receiving', 'sharing', 'welcome'], group: 'People' },
  { emoji: '🤲', tags: ['palms up together', 'begging', 'asking', 'please', 'giving'], group: 'People' },
  { emoji: '🤝', tags: ['handshake', 'agreement', 'deal', 'partnership', 'greeting'], group: 'People' },
  { emoji: '🙏', tags: ['folded hands', 'praying', 'please', 'thanks', 'supplication'], group: 'People' },
  { emoji: '✍️', tags: ['writing hand', 'signature', 'note', 'pen', 'compose'], group: 'People' },
  { emoji: '💅', tags: ['nail polish', 'manicure', 'beauty', 'fashion', 'cosmetics'], group: 'People' },
  { emoji: '🤳', tags: ['selfie', 'phone', 'photo', 'camera', 'self-portrait'], group: 'People' },
  { emoji: '💪', tags: ['flexed biceps', 'strength', 'muscles', 'power', 'fitness'], group: 'People' },
  { emoji: '🦾', tags: ['mechanical arm', 'prosthetic', 'robotic', 'bionic', 'strength'], group: 'People' },
  { emoji: '🦿', tags: ['mechanical leg', 'prosthetic', 'robotic', 'bionic', 'mobility'], group: 'People' },
  { emoji: '🦵', tags: ['leg', 'muscle', 'limb', 'kick', 'strength'], group: 'People' },
  { emoji: '🦶', tags: ['foot', 'toes', 'walking', 'balance', 'movement'], group: 'People' },
  { emoji: '👂', tags: ['ear', 'hearing', 'listen', 'sound', 'perception'], group: 'People' },
  { emoji: '🦻', tags: ['ear with hearing aid', 'deaf', 'hearing impaired', 'assistive device'], group: 'People' },
  { emoji: '👃', tags: ['nose', 'smell', 'scent', 'sniff', 'perception'], group: 'People' },
  { emoji: '🧠', tags: ['brain', 'mind', 'intelligence', 'thought', 'knowledge'], group: 'People' },
  { emoji: '🦷', tags: ['tooth', 'dentist', 'oral health', 'smile', 'dentistry'], group: 'People' },
  { emoji: '🦴', tags: ['bone', 'skeleton', 'anatomy', 'strength', 'health'], group: 'People' },
  { emoji: '👀', tags: ['eyes', 'look', 'watch', 'vision', 'perception'], group: 'People' },
  { emoji: '👅', tags: ['tongue', 'taste', 'lick', 'sense', 'playful'], group: 'People' },
  { emoji: '👄', tags: ['mouth', 'lips', 'kiss', 'speak', 'communication'], group: 'People' },
  { emoji: '👣', tags: ['footprints', 'walking', 'trail', 'tracking', 'journey'], group: 'People' },
  { emoji: '🧬', tags: ['DNA', 'genetics', 'biology', 'science', 'inheritance'], group: 'People' },
  { emoji: '🩸', tags: ['drop of blood', 'donate', 'medical', 'life', 'vampire'], group: 'People' },
  { emoji: '👶', tags: ['baby', 'infant', 'child', 'newborn', 'parenthood'], group: 'People' },
  { emoji: '🧒', tags: ['child', 'kid', 'young', 'innocent', 'youth'], group: 'People' },
  { emoji: '👦', tags: ['boy', 'male child', 'son', 'youth', 'adolescent'], group: 'People' },
  { emoji: '👧', tags: ['girl', 'female child', 'daughter', 'youth', 'adolescent'], group: 'People' },
  { emoji: '🧑', tags: ['adult', 'person', 'gender-neutral', 'individual', 'human'], group: 'People' },
  { emoji: '👱', tags: ['person: blond hair', 'young', 'blond', 'youth'], group: 'People' },
  { emoji: '👨', tags: ['man', 'male', 'gentleman', 'guy', 'human'], group: 'People' },
  { emoji: '🧔', tags: ['man: beard', 'male', 'facial hair', 'gentleman', 'guy'], group: 'People' },
  { emoji: '🧔‍♂️', tags: ['man: beard', 'male', 'facial hair', 'gentleman', 'guy'], group: 'People' },
  { emoji: '🧔‍♀️', tags: ['woman: beard', 'female', 'facial hair', 'gender diversity'], group: 'People' },
  { emoji: '👨‍🦰', tags: ['man: red hair', 'male', 'ginger', 'gentleman', 'guy'], group: 'People' },
  { emoji: '👨‍🦱', tags: ['man: curly hair', 'male', 'curly', 'gentleman', 'guy'], group: 'People' },
  { emoji: '👨‍🦳', tags: ['man: white hair', 'male', 'gray', 'gentleman', 'guy'], group: 'People' },
  { emoji: '👨‍🦲', tags: ['man: bald', 'male', 'gentleman', 'guy', 'hairless'], group: 'People' },
  { emoji: '👩', tags: ['woman', 'female', 'lady', 'girl', 'human'], group: 'People' },
  { emoji: '👩‍🦰', tags: ['woman: red hair', 'female', 'ginger', 'lady', 'girl'], group: 'People' },
  { emoji: '🧑‍🦰', tags: ['person: red hair', 'ginger', 'gender-neutral', 'individual'], group: 'People' },
  { emoji: '👩‍🦱', tags: ['woman: curly hair', 'female', 'curly', 'lady', 'girl'], group: 'People' },
  { emoji: '🧑‍🦱', tags: ['person: curly hair', 'curly', 'gender-neutral', 'individual'], group: 'People' },
  { emoji: '👩‍🦳', tags: ['woman: white hair', 'female', 'gray', 'lady', 'girl'], group: 'People' },
  { emoji: '🧑‍🦳', tags: ['person: white hair', 'gray', 'gender-neutral', 'individual'], group: 'People' },
  { emoji: '👩‍🦲', tags: ['woman: bald', 'female', 'lady', 'girl', 'hairless'], group: 'People' },
  { emoji: '🧑‍🦲', tags: ['person: bald', 'gender-neutral', 'hairless', 'individual'], group: 'People' },
  { emoji: '👱‍♀️', tags: ['woman: blond hair', 'female', 'blond', 'lady', 'girl'], group: 'People' },
  { emoji: '👱‍♂️', tags: ['man: blond hair', 'male', 'blond', 'gentleman', 'guy'], group: 'People' },
  { emoji: '🧓', tags: ['older adult', 'senior', 'elderly', 'aged', 'experienced'], group: 'People' },
  { emoji: '👴', tags: ['old man', 'elderly', 'grandfather', 'aged', 'senior'], group: 'People' },
  { emoji: '👵', tags: ['old woman', 'elderly', 'grandmother', 'aged', 'senior'], group: 'People' },
  { emoji: '🧏', tags: ['deaf person', 'hearing impaired', 'communication', 'sign language'], group: 'People' },
  { emoji: '🧏‍♂️', tags: ['deaf man', 'hearing impaired', 'communication', 'sign language'], group: 'People' },
  { emoji: '🧏‍♀️', tags: ['deaf woman', 'hearing impaired', 'communication', 'sign language'], group: 'People' },
  { emoji: '👳', tags: ['person wearing turban', 'turban', 'headwear', 'cultural diversity'], group: 'People' },
  { emoji: '👳‍♂️', tags: ['man wearing turban', 'turban', 'headwear', 'cultural diversity'], group: 'People' },
  { emoji: '👳‍♀️', tags: ['woman wearing turban', 'turban', 'headwear', 'cultural diversity'], group: 'People' },
  { emoji: '👲', tags: ['person with skullcap', 'skullcap', 'headwear', 'cultural diversity'], group: 'People' },
  { emoji: '🧕', tags: ['woman with headscarf', 'hijab', 'headwear', 'cultural diversity'], group: 'People' },
  { emoji: '🤰', tags: ['pregnant woman', 'expecting', 'motherhood', 'parenthood'], group: 'People' },
  { emoji: '👼', tags: ['baby angel', 'angelic', 'innocence', 'spiritual', 'heavenly'], group: 'People' },
  { emoji: '🗣️', tags: ['speaking head', 'talk', 'chat', 'communication', 'discussion'], group: 'People' },
  { emoji: '👤', tags: ['bust in silhouette', 'anonymous', 'person', 'identity', 'figure'], group: 'People' },
  { emoji: '👥', tags: ['busts in silhouette', 'group', 'people', 'team', 'together'], group: 'People' },
  { emoji: '🦰', tags: ['red hair', 'ginger', 'hair color', 'unique', 'individual'], group: 'People' },
  { emoji: '🦱', tags: ['curly hair', 'hair texture', 'unique', 'individual', 'stylish'], group: 'People' },
  { emoji: '🦳', tags: ['white hair', 'gray', 'aging', 'unique', 'individual'], group: 'People' },
  { emoji: '🦲', tags: ['bald', 'hairless', 'shaved head', 'unique', 'individual'], group: 'People' },
  { emoji: '🐵', tags: ['monkey', 'animal', 'primate', 'mammal', 'wild'], group: 'Nature' },
  { emoji: '🐒', tags: ['monkey', 'primate', 'tail', 'mammal', 'wild'], group: 'Nature' },
  { emoji: '🦍', tags: ['gorilla', 'primate', 'ape', 'mammal', 'wild'], group: 'Nature' },
  { emoji: '🦧', tags: ['orangutan', 'primate', 'ape', 'mammal', 'wild'], group: 'Nature' },
  { emoji: '🐶', tags: ['dog', 'puppy', 'pet', 'animal', 'mammal'], group: 'Nature' },
  { emoji: '🐕', tags: ['dog', 'animal', 'pet', 'mammal', 'domesticated'], group: 'Nature' },
  { emoji: '🦮', tags: ['guide dog', 'service animal', 'dog', 'pet', 'assistive'], group: 'Nature' },
  { emoji: '🐕‍🦺', tags: ['service dog', 'pet', 'guide dog', 'assistive', 'animal'], group: 'Nature' },
  { emoji: '🐩', tags: ['poodle', 'dog', 'pet', 'animal', 'mammal'], group: 'Nature' },
  { emoji: '🐺', tags: ['wolf', 'animal', 'wild', 'mammal', 'canine'], group: 'Nature' },
  { emoji: '🦊', tags: ['fox', 'animal', 'wild', 'mammal', 'sly'], group: 'Nature' },
  { emoji: '🦝', tags: ['raccoon', 'animal', 'wild', 'mammal', 'trash panda'], group: 'Nature' },
  { emoji: '🐱', tags: ['cat', 'pet', 'animal', 'feline', 'mammal'], group: 'Nature' },
  { emoji: '🐈', tags: ['cat', 'animal', 'pet', 'feline', 'domesticated'], group: 'Nature' },
  { emoji: '🐈‍⬛', tags: ['black cat', 'superstition', 'feline', 'pet', 'animal'], group: 'Nature' },
  { emoji: '🦁', tags: ['lion', 'animal', 'wild', 'feline', 'king of the jungle'], group: 'Nature' },
  { emoji: '🐯', tags: ['tiger', 'animal', 'wild', 'feline', 'mammal'], group: 'Nature' },
  { emoji: '🐅', tags: ['tiger', 'animal', 'wild', 'big cat', 'feline'], group: 'Nature' },
  { emoji: '🐆', tags: ['leopard', 'animal', 'wild', 'big cat', 'feline'], group: 'Nature' },
  { emoji: '🐴', tags: ['horse', 'animal', 'mammal', 'farm', 'ride'], group: 'Nature' },
  { emoji: '🫎', tags: ['moose', 'animal', 'wild', 'mammal', 'antlers'], group: 'Nature' },
  { emoji: '🫏', tags: ['donkey', 'animal', 'mammal', 'farm', 'pack animal'], group: 'Nature' },
  { emoji: '🐎', tags: ['horse', 'animal', 'mammal', 'wild', 'ride'], group: 'Nature' },
  { emoji: '🦄', tags: ['unicorn', 'mythical', 'fantasy', 'animal', 'horse'], group: 'Nature' },
  { emoji: '🦓', tags: ['zebra', 'animal', 'wild', 'striped', 'mammal'], group: 'Nature' },
  { emoji: '🦌', tags: ['deer', 'animal', 'wild', 'mammal', 'antlers'], group: 'Nature' },
  { emoji: '🦬', tags: ['bison', 'animal', 'wild', 'mammal', 'buffalo'], group: 'Nature' },
  { emoji: '🐮', tags: ['cow', 'animal', 'farm', 'mammal', 'livestock'], group: 'Nature' },
  { emoji: '🐂', tags: ['ox', 'animal', 'farm', 'mammal', 'livestock'], group: 'Nature' },
  { emoji: '🐃', tags: ['water buffalo', 'animal', 'wild', 'mammal', 'farm'], group: 'Nature' },
  { emoji: '🐄', tags: ['cow', 'animal', 'farm', 'livestock', 'mammal'], group: 'Nature' },
  { emoji: '🐷', tags: ['pig', 'animal', 'farm', 'mammal', 'livestock'], group: 'Nature' },
  { emoji: '🐖', tags: ['pig', 'animal', 'mammal', 'farm', 'livestock'], group: 'Nature' },
  { emoji: '🐗', tags: ['boar', 'animal', 'wild', 'mammal', 'pig'], group: 'Nature' },
  { emoji: '🐽', tags: ['pig nose', 'snout', 'animal', 'pig', 'mammal'], group: 'Nature' },
  { emoji: '🐏', tags: ['ram', 'animal', 'wild', 'mammal', 'sheep'], group: 'Nature' },
  { emoji: '🐑', tags: ['sheep', 'animal', 'farm', 'mammal', 'wool'], group: 'Nature' },
  { emoji: '🐐', tags: ['goat', 'animal', 'farm', 'mammal', 'wild'], group: 'Nature' },
  { emoji: '🐪', tags: ['camel', 'animal', 'desert', 'mammal', 'hump'], group: 'Nature' },
  { emoji: '🐫', tags: ['bactrian camel', 'animal', 'desert', 'mammal', 'humps'], group: 'Nature' },
  { emoji: '🦙', tags: ['llama', 'animal', 'farm', 'mammal', 'alpaca'], group: 'Nature' },
  { emoji: '🦒', tags: ['giraffe', 'animal', 'wild', 'mammal', 'tall'], group: 'Nature' },
  { emoji: '🐘', tags: ['elephant', 'animal', 'wild', 'mammal', 'large'], group: 'Nature' },
  { emoji: '🦣', tags: ['mammoth', 'prehistoric', 'animal', 'mammal', 'extinct'], group: 'Nature' },
  { emoji: '🦏', tags: ['rhinoceros', 'animal', 'wild', 'mammal', 'horn'], group: 'Nature' },
  { emoji: '🦛', tags: ['hippopotamus', 'animal', 'wild', 'mammal', 'water'], group: 'Nature' },
  { emoji: '🐭', tags: ['mouse', 'animal', 'rodent', 'mammal', 'small'], group: 'Nature' },
  { emoji: '🐁', tags: ['mouse', 'animal', 'rodent', 'small', 'mammal'], group: 'Nature' },
  { emoji: '🐀', tags: ['rat', 'rodent', 'animal', 'small', 'mammal'], group: 'Nature' },
  { emoji: '🐹', tags: ['hamster', 'rodent', 'animal', 'pet', 'small'], group: 'Nature' },
  { emoji: '🐰', tags: ['rabbit', 'animal', 'mammal', 'bunny', 'pet'], group: 'Nature' },
  { emoji: '🐇', tags: ['rabbit', 'animal', 'wild', 'mammal', 'bunny'], group: 'Nature' },
  { emoji: '🐿️', tags: ['chipmunk', 'rodent', 'animal', 'wild', 'mammal'], group: 'Nature' },
  { emoji: '🦫', tags: ['beaver', 'animal', 'wild', 'mammal', 'rodent'], group: 'Nature' },
  { emoji: '🦔', tags: ['hedgehog', 'animal', 'wild', 'mammal', 'spiky'], group: 'Nature' },
  { emoji: '🦇', tags: ['bat', 'animal', 'nocturnal', 'wild', 'mammal'], group: 'Nature' },
  { emoji: '🐻', tags: ['bear', 'animal', 'wild', 'mammal', 'carnivore'], group: 'Nature' },
  { emoji: '🐻‍❄️', tags: ['polar bear', 'animal', 'wild', 'mammal', 'cold'], group: 'Nature' },
  { emoji: '🐨', tags: ['koala', 'animal', 'marsupial', 'wild', 'mammal'], group: 'Nature' },
  { emoji: '🐼', tags: ['panda', 'animal', 'wild', 'bear', 'mammal'], group: 'Nature' },
  { emoji: '🦥', tags: ['sloth', 'animal', 'wild', 'mammal', 'slow'], group: 'Nature' },
  { emoji: '🦦', tags: ['otter', 'animal', 'wild', 'mammal', 'water'], group: 'Nature' },
  { emoji: '🦨', tags: ['skunk', 'animal', 'wild', 'mammal', 'smell'], group: 'Nature' },
  { emoji: '🦘', tags: ['kangaroo', 'marsupial', 'animal', 'wild', 'mammal'], group: 'Nature' },
  { emoji: '🦡', tags: ['badger', 'animal', 'wild', 'mammal', 'digging'], group: 'Nature' },
  { emoji: '🐾', tags: ['paw prints', 'animal', 'footprints', 'tracks', 'paws'], group: 'Nature' },
  { emoji: '🦃', tags: ['turkey', 'animal', 'bird', 'thanksgiving', 'farm'], group: 'Nature' },
  { emoji: '🐔', tags: ['chicken', 'animal', 'bird', 'farm', 'poultry'], group: 'Nature' },
  { emoji: '🐓', tags: ['rooster', 'animal', 'bird', 'farm', 'poultry'], group: 'Nature' },
  { emoji: '🐣', tags: ['hatching chick', 'animal', 'bird', 'baby', 'chick'], group: 'Nature' },
  { emoji: '🐤', tags: ['baby chick', 'animal', 'bird', 'young', 'chick'], group: 'Nature' },
  { emoji: '🐥', tags: ['front-facing chick', 'animal', 'bird', 'chick', 'cute'], group: 'Nature' },
  { emoji: '🐦', tags: ['bird', 'animal', 'wild', 'avian', 'fly'], group: 'Nature' },
  { emoji: '🐧', tags: ['penguin', 'animal', 'bird', 'wild', 'cold'], group: 'Nature' },
  { emoji: '🕊️', tags: ['dove', 'bird', 'animal', 'peace', 'fly'], group: 'Nature' },
  { emoji: '🦅', tags: ['eagle', 'bird', 'animal', 'wild', 'fly'], group: 'Nature' },
  { emoji: '🦆', tags: ['duck', 'animal', 'bird', 'water', 'wild'], group: 'Nature' },
  { emoji: '🦢', tags: ['swan', 'bird', 'animal', 'water', 'wild'], group: 'Nature' },
  { emoji: '🦉', tags: ['owl', 'bird', 'animal', 'nocturnal', 'wild'], group: 'Nature' },
  { emoji: '🦤', tags: ['dodo', 'bird', 'animal', 'extinct', 'wild'], group: 'Nature' },
  { emoji: '🪶', tags: ['feather', 'bird', 'animal', 'light', 'flight'], group: 'Nature' },
  { emoji: '🦩', tags: ['flamingo', 'bird', 'animal', 'pink', 'wild'], group: 'Nature' },
  { emoji: '🦚', tags: ['peacock', 'bird', 'animal', 'colorful', 'wild'], group: 'Nature' },
  { emoji: '🦜', tags: ['parrot', 'bird', 'animal', 'colorful', 'talk'], group: 'Nature' },
  { emoji: '🪽', tags: ['wing', 'animal', 'bird', 'fly', 'flight'], group: 'Nature' },
  { emoji: '🐦‍⬛', tags: ['black bird', 'animal', 'bird', 'dark', 'avian'], group: 'Nature' },
  { emoji: '🪿', tags: ['goose', 'bird', 'animal', 'wild', 'avian'], group: 'Nature' },
  { emoji: '🐦‍🔥', tags: ['phoenix', 'mythical', 'bird', 'fire', 'rebirth'], group: 'Nature' },
  { emoji: '🪹', tags: ['nest', 'bird', 'animal', 'home', 'wild'], group: 'Nature' },
  { emoji: '🪺', tags: ['nest with eggs', 'bird', 'animal', 'home', 'wild'], group: 'Nature' },
  { emoji: '🐸', tags: ['frog', 'animal', 'amphibian', 'green', 'croak'], group: 'Nature' },
  { emoji: '🐊', tags: ['crocodile', 'animal', 'wild', 'reptile', 'water'], group: 'Nature' },
  { emoji: '🐢', tags: ['turtle', 'animal', 'reptile', 'wild', 'slow'], group: 'Nature' },
  { emoji: '🦎', tags: ['lizard', 'animal', 'reptile', 'wild', 'cold-blooded'], group: 'Nature' },
  { emoji: '🐍', tags: ['snake', 'animal', 'reptile', 'wild', 'slither'], group: 'Nature' },
  { emoji: '🐲', tags: ['dragon', 'mythical', 'reptile', 'fire', 'fantasy'], group: 'Nature' },
  { emoji: '🐉', tags: ['dragon', 'mythical', 'fantasy', 'reptile', 'legend'], group: 'Nature' },
  { emoji: '🦕', tags: ['sauropod', 'dinosaur', 'animal', 'prehistoric', 'extinct'], group: 'Nature' },
  { emoji: '🦖', tags: ['t-rex', 'dinosaur', 'animal', 'prehistoric', 'extinct'], group: 'Nature' },
  { emoji: '🐳', tags: ['spouting whale', 'animal', 'marine', 'whale', 'ocean'], group: 'Nature' },
  { emoji: '🐋', tags: ['whale', 'animal', 'marine', 'ocean', 'large'], group: 'Nature' },
  { emoji: '🐬', tags: ['dolphin', 'animal', 'marine', 'ocean', 'jump'], group: 'Nature' },
  { emoji: '🦭', tags: ['seal', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🐟', tags: ['fish', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🐠', tags: ['tropical fish', 'animal', 'marine', 'colorful', 'ocean'], group: 'Nature' },
  { emoji: '🐡', tags: ['blowfish', 'animal', 'marine', 'water', 'ocean'], group: 'Nature' },
  { emoji: '🦈', tags: ['shark', 'animal', 'marine', 'ocean', 'wild'], group: 'Nature' },
  { emoji: '🐙', tags: ['octopus', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🐚', tags: ['shell', 'animal', 'marine', 'ocean', 'sea'], group: 'Nature' },
  { emoji: '🪸', tags: ['coral', 'animal', 'marine', 'ocean', 'sea'], group: 'Nature' },
  { emoji: '🪼', tags: ['jellyfish', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🦀', tags: ['crab', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🦞', tags: ['lobster', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🦐', tags: ['shrimp', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🦑', tags: ['squid', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🦪', tags: ['oyster', 'animal', 'marine', 'ocean', 'water'], group: 'Nature' },
  { emoji: '🐌', tags: ['snail', 'animal', 'slow', 'mollusk', 'insect'], group: 'Nature' },
  { emoji: '🦋', tags: ['butterfly', 'animal', 'insect', 'beautiful', 'flying'], group: 'Nature' },
  { emoji: '🐛', tags: ['caterpillar', 'animal', 'insect', 'larva', 'metamorphosis'], group: 'Nature' },
  { emoji: '🐜', tags: ['ant', 'animal', 'insect', 'colony', 'small'], group: 'Nature' },
  { emoji: '🐝', tags: ['bee', 'insect', 'honey', 'pollinator', 'animal'], group: 'Nature' },
  { emoji: '🪲', tags: ['beetle', 'insect', 'animal', 'bug', 'small'], group: 'Nature' },
  { emoji: '🐞', tags: ['ladybug', 'insect', 'animal', 'red', 'spot'], group: 'Nature' },
  { emoji: '🦗', tags: ['cricket', 'insect', 'animal', 'chirp', 'small'], group: 'Nature' },
  { emoji: '🪳', tags: ['cockroach', 'insect', 'animal', 'pest', 'small'], group: 'Nature' },
  { emoji: '🕷️', tags: ['spider', 'insect', 'arachnid', 'animal', 'web'], group: 'Nature' },
  { emoji: '🕸️', tags: ['spider web', 'web', 'insect', 'trap', 'animal'], group: 'Nature' },
  { emoji: '🦂', tags: ['scorpion', 'insect', 'arachnid', 'animal', 'sting'], group: 'Nature' },
  { emoji: '🦟', tags: ['mosquito', 'insect', 'animal', 'pest', 'bug'], group: 'Nature' },
  { emoji: '🪰', tags: ['fly', 'insect', 'pest', 'animal', 'small'], group: 'Nature' },
  { emoji: '🪱', tags: ['worm', 'animal', 'insect', 'small', 'ground'], group: 'Nature' },
  { emoji: '🦠', tags: ['microbe', 'bacteria', 'germ', 'virus', 'small'], group: 'Nature' },
  { emoji: '💐', tags: ['bouquet', 'flowers', 'gift', 'floral'], group: 'Nature' },
  { emoji: '🌸', tags: ['cherry blossom', 'flower', 'floral', 'spring'], group: 'Nature' },
  { emoji: '💮', tags: ['white flower', 'floral', 'flower', 'petal'], group: 'Nature' },
  { emoji: '🪷', tags: ['lotus', 'flower', 'water', 'floral'], group: 'Nature' },
  { emoji: '🏵️', tags: ['rosette', 'flower', 'award', 'floral'], group: 'Nature' },
  { emoji: '🌹', tags: ['rose', 'flower', 'love', 'floral'], group: 'Nature' },
  { emoji: '🥀', tags: ['wilted flower', 'flower', 'floral', 'dead'], group: 'Nature' },
  { emoji: '🌺', tags: ['hibiscus', 'flower', 'floral', 'bloom'], group: 'Nature' },
  { emoji: '🌻', tags: ['sunflower', 'flower', 'floral', 'bright'], group: 'Nature' },
  { emoji: '🌼', tags: ['blossom', 'flower', 'floral', 'spring'], group: 'Nature' },
  { emoji: '🌷', tags: ['tulip', 'flower', 'floral', 'spring'], group: 'Nature' },
  { emoji: '🪻', tags: ['lavender', 'flower', 'floral', 'purple'], group: 'Nature' },
  { emoji: '🌱', tags: ['seedling', 'plant', 'growth', 'sprout'], group: 'Nature' },
  { emoji: '🪴', tags: ['potted plant', 'plant', 'green', 'growth'], group: 'Nature' },
  { emoji: '🌲', tags: ['evergreen tree', 'tree', 'forest', 'plant'], group: 'Nature' },
  { emoji: '🌳', tags: ['deciduous tree', 'tree', 'forest', 'plant'], group: 'Nature' },
  { emoji: '🌴', tags: ['palm tree', 'tree', 'beach', 'tropical'], group: 'Nature' },
  { emoji: '🌵', tags: ['cactus', 'plant', 'desert', 'succulent'], group: 'Nature' },
  { emoji: '🌾', tags: ['sheaf of rice', 'plant', 'harvest', 'grain'], group: 'Nature' },
  { emoji: '🌿', tags: ['herb', 'plant', 'green', 'leaves'], group: 'Nature' },
  { emoji: '☘️', tags: ['shamrock', 'plant', 'irish', 'luck'], group: 'Nature' },
  { emoji: '🍀', tags: ['four leaf clover', 'plant', 'luck', 'irish'], group: 'Nature' },
  { emoji: '🍁', tags: ['maple leaf', 'leaf', 'canada', 'fall'], group: 'Nature' },
  { emoji: '🍂', tags: ['fallen leaf', 'autumn', 'leaf', 'fall'], group: 'Nature' },
  { emoji: '🍃', tags: ['leaf fluttering', 'leaf', 'wind', 'plant'], group: 'Nature' },
  { emoji: '🍄', tags: ['mushroom', 'fungus', 'plant', 'forest'], group: 'Nature' },
  { emoji: '🪨', tags: ['rock', 'stone', 'hard', 'mineral'], group: 'Nature' },
  { emoji: '🪵', tags: ['wood', 'log', 'forest', 'material'], group: 'Nature' },
  { emoji: '🌑', tags: ['new moon', 'space', 'sky', 'dark'], group: 'Nature' },
  { emoji: '🌒', tags: ['waxing crescent moon', 'sky', 'space', 'night'], group: 'Nature' },
  { emoji: '🌓', tags: ['first quarter moon', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '🌔', tags: ['waxing gibbous moon', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '🌕', tags: ['full moon', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '🌖', tags: ['waning gibbous moon', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '🌗', tags: ['last quarter moon', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '🌘', tags: ['waning crescent moon', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '🌙', tags: ['crescent moon', 'space', 'night', 'sky'], group: 'Nature' },
  { emoji: '🌚', tags: ['new moon face', 'space', 'sky', 'dark'], group: 'Nature' },
  { emoji: '🌛', tags: ['first quarter moon face', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '🌜', tags: ['last quarter moon face', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '☀️', tags: ['sun', 'day', 'sky', 'bright'], group: 'Nature' },
  { emoji: '🌝', tags: ['full moon face', 'space', 'night', 'sky'], group: 'Nature' },
  { emoji: '🌞', tags: ['sun face', 'day', 'sky', 'bright'], group: 'Nature' },
  { emoji: '🪐', tags: ['planet', 'space', 'saturn', 'astronomy'], group: 'Nature' },
  { emoji: '⭐', tags: ['star', 'space', 'sky', 'night'], group: 'Nature' },
  { emoji: '🌟', tags: ['glowing star', 'space', 'sky', 'bright'], group: 'Nature' },
  { emoji: '🌠', tags: ['shooting star', 'space', 'sky', 'wish'], group: 'Nature' },
  { emoji: '🌌', tags: ['milky way', 'space', 'stars', 'sky'], group: 'Nature' },
  { emoji: '☁️', tags: ['cloud', 'sky', 'weather', 'white'], group: 'Nature' },
  { emoji: '⛅', tags: ['sun behind cloud', 'sky', 'weather', 'partly sunny'], group: 'Nature' },
  { emoji: '⛈️', tags: ['thunder cloud', 'storm', 'lightning', 'weather'], group: 'Nature' },
  { emoji: '🌤️', tags: ['sun behind small cloud', 'weather', 'sky', 'cloudy'], group: 'Nature' },
  { emoji: '🌥️', tags: ['sun behind large cloud', 'sky', 'weather', 'cloudy'], group: 'Nature' },
  { emoji: '🌦️', tags: ['sun behind rain cloud', 'weather', 'rain', 'sky'], group: 'Nature' },
  { emoji: '🌧️', tags: ['cloud with rain', 'weather', 'rain', 'sky'], group: 'Nature' },
  { emoji: '🌨️', tags: ['cloud with snow', 'weather', 'snow', 'sky'], group: 'Nature' },
  { emoji: '🌩️', tags: ['cloud with lightning', 'weather', 'storm', 'lightning'], group: 'Nature' },
  { emoji: '🌪️', tags: ['tornado', 'storm', 'weather', 'wind'], group: 'Nature' },
  { emoji: '🌫️', tags: ['fog', 'weather', 'cloud', 'sky'], group: 'Nature' },
  { emoji: '🌬️', tags: ['wind face', 'wind', 'sky', 'weather'], group: 'Nature' },
  { emoji: '🌀', tags: ['cyclone', 'storm', 'weather', 'tornado'], group: 'Nature' },
  { emoji: '🌈', tags: ['rainbow', 'weather', 'sky', 'colorful'], group: 'Nature' },
  { emoji: '🌂', tags: ['umbrella closed', 'weather', 'rain', 'protection'], group: 'Nature' },
  { emoji: '☂️', tags: ['umbrella', 'weather', 'rain', 'protection'], group: 'Nature' },
  { emoji: '☔', tags: ['umbrella with rain', 'weather', 'rain', 'protection'], group: 'Nature' },
  { emoji: '⛱️', tags: ['umbrella on ground', 'beach', 'sun', 'protection'], group: 'Nature' },
  { emoji: '⚡', tags: ['lightning', 'electric', 'storm', 'weather'], group: 'Nature' },
  { emoji: '❄️', tags: ['snowflake', 'cold', 'winter', 'snow'], group: 'Nature' },
  { emoji: '☃️', tags: ['snowman', 'winter', 'snow', 'cold'], group: 'Nature' },
  { emoji: '⛄', tags: ['snowman without snow', 'winter', 'snow', 'cold'], group: 'Nature' },
  { emoji: '☄️', tags: ['comet', 'space', 'sky', 'fire'], group: 'Nature' },
  { emoji: '🔥', tags: ['fire', 'hot', 'flame', 'burn'], group: 'Nature' },
  { emoji: '💧', tags: ['droplet', 'water', 'liquid', 'wet'], group: 'Nature' },
  { emoji: '🌊', tags: ['water wave', 'ocean', 'sea', 'water'], group: 'Nature' },
];
