import styled from 'styled-components';
import { styled as styledMUI } from '@mui/system';
import { Button } from '@mui/material';

import { ReactComponent as LaptopSVG } from 'assets/svg/home/laptop.svg';
import { ReactComponent as MonitorSVG } from 'assets/svg/home/monitor.svg';
import { hexToRGBA } from 'utils/commonUtils';
import { THEME } from 'utils/constants';

export const CoreContainer = styled.div`
  background: linear-gradient(326deg, #fc3a4c -25%, #9b6afb 100%);
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
  @media only screen and (max-height: 800px) {
    justify-content: center;
    padding: 5rem 0;
  }
`;

export const ParticleElementsWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
  @media only screen and (max-height: 800px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 4rem;
  color: #fff;
`;

export const LightContainer = styled(Container)`
  background-color: ${THEME.PRIMARY};
`;
export const DarkContainer = styled(Container)`
  background-color: ${THEME.SECONDARY};
`;

export const SpecialContainer = styled(LightContainer)`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${THEME.PRIMARY};
    transform-origin: top left;
    transform: skewY(-3deg);
  }
`;

export const StyledCoreButton = styledMUI(Button)((props: { angle: number }) => ({
  'background': `linear-gradient(${props.angle}deg, ${hexToRGBA(THEME.ACCENT, 1.25)} 0px, ${THEME.ACCENT} 90%)`,
  'border': 0,
  'borderRadius': 100,
  'color': THEME.IMPORTANT_TEXT,
  'fontWeight': 'bold',
  'height': 80,
  'width': 400,
  'fontFamily': `Bebas Neue`,
  'textTransform': 'uppercase',
  'letterSpacing': '1px',
  'fontSize': '36px',
  'padding': '0 30px',
  'transition': 'box-shadow 0.5s',
  '&:hover': {
    boxShadow: `0 4px 24px 4px ${hexToRGBA(THEME.ACCENT, 1, 0.2)}`,
  },
  '@media only screen and (max-width: 480px)': {
    fontSize: '24px',
    width: 200,
  },
}));

export const StyledLaptopSVG = styled(LaptopSVG)`
  position: absolute;
  left: 6%;
  bottom: 0%;
  width: 579px;
  height: 700px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  @media only screen and (max-height: 800px) {
    display: none;
  }
`;

export const StyledMonitorSVG = styled(MonitorSVG)`
  position: absolute;
  left: 21%;
  bottom: 35px;
  width: 579px;
  height: 700px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  @media only screen and (max-height: 800px) {
    display: none;
  }
`;
