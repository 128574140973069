import { useTranslation } from 'react-i18next';
import { Stack, Typography, IconButton, Tooltip, Divider } from '@mui/material';
import { Inbox, DoneAll } from '@mui/icons-material';

import { THEME } from 'utils/constants';

import { NotificationsPanelWrapper } from './style';

const NotificationsPanel = () => {
  const { t } = useTranslation();

  return (
    <NotificationsPanelWrapper>
      <Stack flexDirection={'column'} justifyContent="space-between" width="100%">
        <Stack p={2} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
            <Inbox fontSize="large" />
            <Typography variant="h5" color={THEME.TEXT} fontWeight={'bold'}>
              {t('notifications')}
            </Typography>
          </Stack>
          <Stack>
            <Tooltip title="Mark All as Read">
              <IconButton sx={{ bgcolor: THEME.SECONDARY }}>
                <DoneAll sx={{ color: THEME.IMPORTANT_TEXT }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Divider />
        <Stack p={2} height={'100%'} overflow={'auto'} justifyContent={'center'} alignItems={'center'}>
          <Typography variant="body1" fontWeight={'bold'}>
            {t('no_notifications')}
          </Typography>
          <Typography variant="body1">{t('no_notifications_description')}</Typography>
        </Stack>
      </Stack>
    </NotificationsPanelWrapper>
  );
};

export default NotificationsPanel;
