import { useContext, useMemo, createContext, useRef, useEffect } from 'react';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography, Stack, IconButton } from '@mui/material';

import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { SoundCloudSVG, TwitchSVG, YouTubeSVG } from 'assets/SVG';
import { truncateText } from 'utils/commonUtils';
import { THEME } from 'utils/constants';
import { RoomContext } from 'views/Room/Room';
import { MediaType } from 'views/Room/socketEventTypes';

import { IPlaylistItemDraggableNodeProps } from './types';
import { SortableItem, DragHandleBtn, DragHandleSvg } from './style';

const SortableItemContext = createContext<{
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}>({
  attributes: {},
  listeners: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ref: () => {},
});

export const DragHandle = () => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);
  return (
    <DragHandleBtn {...attributes} {...listeners} ref={ref}>
      <DragHandleSvg viewBox="0 0 20 20" width="24">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </DragHandleSvg>
    </DragHandleBtn>
  );
};

const getThumbnailImageSource = (source: MediaType, videoId: string) => {
  if (source === 'YouTube') {
    return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
  } else if (source === 'Twitch') {
    return `https://static-cdn.jtvnw.net/previews-ttv/live_user_${videoId}-140x100.jpg`;
  } else if (source === 'SoundCloud') {
    return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
  }
  return ``;
};

const PlaylistItem = (props: PropsWithChildren<IPlaylistItemDraggableNodeProps>) => {
  const { t } = useTranslation();
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: props.id,
  });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );
  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const thumnailImageSource = useMemo(
    () => getThumbnailImageSource(props.mediaType, props.videoId),
    [props.mediaType, props.videoId],
  );

  return (
    <SortableItemContext.Provider value={context}>
      <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
        <SortableItem ref={setNodeRef} style={style} {...attributes}>
          <img
            src={thumnailImageSource}
            style={{ maxHeight: '100px', width: '140px', marginLeft: '0', overflow: 'hidden' }}
            loading="lazy"
          />
          <Stack flexDirection={'row'} flex={1} p={2} justifyContent={'space-between'}>
            <Stack gap={1}>
              <Tooltip title={props.title}>
                <Typography fontSize={'14px'}>{truncateText(props.title, 30)}</Typography>
              </Tooltip>
              {props.mediaType === 'YouTube' && <YouTubeSVG width={75} height={25} onlyLogo />}
              {props.mediaType === 'Twitch' && <TwitchSVG width={75} height={25} onlyLogo />}
              {props.mediaType === 'SoundCloud' && <SoundCloudSVG width={75} height={25} onlyLogo />}
            </Stack>
            <DragHandle {...listeners} />
            {props.children}
          </Stack>
        </SortableItem>
      </Stack>
    </SortableItemContext.Provider>
  );
};

export default PlaylistItem;
